import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import Sticky from "react-sticky-el";
import "../../assets/scss/style.scss";
import CTA from "../../assets/images/events/2025/festcasestudy/CTA.png";
import Header from "../../assets/images/events/2025/festcasestudy/Header.png";
import Festlogo from "../../assets/images/events/2025/fest/festlogo.svg";
import Watchrecap from "../../assets/images/events/2025/fest/watchrecap.png";
import useModal from '../../components/elements/useModal';
import MandalaShape from "../../assets/images/events/2025/festcasestudy/Mandala.svg";
import Successfulvector from "../../assets/images/events/2025/festcasestudy/successful.svg";
import Networkingvector from "../../assets/images/events/2025/festcasestudy/networking.svg";
import Outsidevector from "../../assets/images/events/2025/festcasestudy/outside.svg";
import Image1 from "../../assets/images/events/2025/festcasestudy/Image1.png";
import Image2 from "../../assets/images/events/2025/festcasestudy/Image2.png";
import Image3 from "../../assets/images/events/2025/festcasestudy/Image3.png";
import Image4 from "../../assets/images/events/2025/festcasestudy/Image4.png";
import Memories from "../../assets/images/events/2025/festcasestudy/memories.png";
import Kongres from "../../assets/images/events/2025/fest/Kongres.png"
import Travel from "../../assets/images/events/2025/fest/Travel.png"
import CMW from "../../assets/images/events/2025/fest/CMW.png"
import AfternoonVector from "../../assets/images/events/2025/fest/AfternoonVector.svg"
import NightsVector from "../../assets/images/events/2025/fest/NightVector.svg"
import ArloLogoBlack from "../../assets/images/events/partner_logos/arlo-logo-black.png"
import MoxyLogoBlack from "../../assets/images/events/partner_logos/moxy-logo-black.png"
import WytheLogoBlack from "../../assets/images/events/partner_logos/wythe-logo-black.png"
import HoxtonLogoBlack from "../../assets/images/events/partner_logos/hoxton-logo-black.png"
import PennyLogoBlack from "../../assets/images/events/partner_logos/penny-logo-black.png"
import NYC from "../../assets/images/events/partner_logos/newyorkcity.svg"

const Carousel = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(1); // Start at 1 to include the initial offset
  const length = items.length;
  const intervalRef = useRef(null);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    startAutoScroll();
    return () => stopAutoScroll();
  }, []);

  const startAutoScroll = () => {
    intervalRef.current = setInterval(() => {
      nextSlide();
    }, 3000); // Change slide every 3 seconds
  };

  const stopAutoScroll = () => {
    clearInterval(intervalRef.current);
  };

  const nextSlide = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const prevSlide = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleTransitionEnd = () => {
    setIsTransitioning(false);
    if (currentIndex >= length + 1) {
      setCurrentIndex(1);
    } else if (currentIndex <= 0) {
      setCurrentIndex(length);
    }
  };

  const getTranslateX = () => {
    const width = window.innerWidth;
    if (width < 768) {
      // On mobile, start at -6% and move -111% each slide
      return -6 + (currentIndex - 1) * -111;
    } else {
      // On desktop, start at -45% and move -65% each slide
      return -45 + (currentIndex - 1) * -65;
    }
  };

  return (
    <div className="testimonialfest">
      <div 
        className="testimonial-inner" 
        style={{ 
          transform: `translateX(${getTranslateX()}%)`,
          transition: isTransitioning ? 'transform 0.5s ease' : 'none',
        }}
        onTransitionEnd={handleTransitionEnd}
      >
        <div className="testimonial-item">{items[length - 1]}</div> {/* Clone of the last item */}
        {items.map((item, index) => (
          <div className="testimonial-item" key={index}>
            {item}
          </div>
        ))}
        <div className="testimonial-item">{items[0]}</div> {/* Clone of the first item */}
      </div>
      <div className="testimonial-indicators">
        {items.map((_, index) => (
          <span
            key={index}
            className={`indicator ${index + 1 === currentIndex || (currentIndex === length + 1 && index === 0) ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index + 1)}
          ></span>
        ))}
      </div>
    </div>
  );
};


const FestCaseStudy = () => {

  const { isShowing, toggle } = useModal();
  const [modal, setModal] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);
  const spinner = () => {
    setVideoLoading(!videoLoading);
  };

  const openModal = () => {
    setModal(!modal);
  };

  const cards = [

    <div className="card"><div className="testimonial-quotation">  <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill="#F77441" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 379.51" 
    style={{width:"26px",display:"block", paddingBottom:"33px"}}>
<path d="M212.27 33.98C131.02 56.52 78.14 103.65 64.99 185.67c-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160.01 60.56 27.12 149.48-159.79 175.36-215.11 92.8-12.87-19.19-21.39-41.59-24.46-66.19C-11.35 159.99 43.48 64.7 139.8 19.94c17.82-8.28 36.6-14.76 56.81-19.51 10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78zm267.78 0c-81.25 22.54-134.14 69.67-147.28 151.69-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160 60.56 27.13 149.48-159.78 175.36-215.1 92.8-12.87-19.19-21.39-41.59-24.46-66.19C256.43 159.99 311.25 64.7 407.58 19.94 425.4 11.66 444.17 5.18 464.39.43c10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78z"/></svg></div>

m&i Fest Marrakesh retains its buyer/supplier personalisation and destination-led experiences in this larger format event, which shows us that m&i is developing for the future, not resting on laurels, which is good for the whole industry
    
    <br/><br/><strong>Habib Amir, Prestigious Venues 
 </strong></div>,

<div className="card"><div className="testimonial-quotation">  <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill="#F77441" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 379.51" 
style={{width:"26px",display:"block", paddingBottom:"33px"}}>
<path d="M212.27 33.98C131.02 56.52 78.14 103.65 64.99 185.67c-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160.01 60.56 27.12 149.48-159.79 175.36-215.11 92.8-12.87-19.19-21.39-41.59-24.46-66.19C-11.35 159.99 43.48 64.7 139.8 19.94c17.82-8.28 36.6-14.76 56.81-19.51 10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78zm267.78 0c-81.25 22.54-134.14 69.67-147.28 151.69-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160 60.56 27.13 149.48-159.78 175.36-215.1 92.8-12.87-19.19-21.39-41.59-24.46-66.19C256.43 159.99 311.25 64.7 407.58 19.94 425.4 11.66 444.17 5.18 464.39.43c10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78z"/></svg></div>

This was the perfect mix of making new connections and catching up with industry colleagues. The staff at m&i were brilliant throughout and did a great job. We always worry about whether our days out of the office will be worth it - but this 100% was. Great work team! Looking forward to the next!

<br/><br/><strong>Danielle Grubb, Gray Dawes Travel 
</strong></div>,




    <div className="card"><div className="testimonial-quotation">  <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill="#F77441" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 379.51" 
    style={{width:"26px",display:"block", paddingBottom:"33px"}}>
<path d="M212.27 33.98C131.02 56.52 78.14 103.65 64.99 185.67c-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160.01 60.56 27.12 149.48-159.79 175.36-215.11 92.8-12.87-19.19-21.39-41.59-24.46-66.19C-11.35 159.99 43.48 64.7 139.8 19.94c17.82-8.28 36.6-14.76 56.81-19.51 10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78zm267.78 0c-81.25 22.54-134.14 69.67-147.28 151.69-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160 60.56 27.13 149.48-159.78 175.36-215.1 92.8-12.87-19.19-21.39-41.59-24.46-66.19C256.43 159.99 311.25 64.7 407.58 19.94 425.4 11.66 444.17 5.18 464.39.43c10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78z"/></svg></div>

m&i Fest 2024 was my first experience with this event format. Although meetings are also central at m&i, compared to other large events like IBTM or IMEX, the connections I made were definitely deepened thanks to the fabulously grand evening events. Consequently, the exchange is more efficient and sustainable. It was simply magnificent.
    
    <br/><br/><strong>Tom Kothe, adebar GmbH</strong></div>,



<div className="card"><div className="testimonial-quotation">  <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill="#F77441" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 379.51" 
style={{width:"26px",display:"block", paddingBottom:"33px"}}>
<path d="M212.27 33.98C131.02 56.52 78.14 103.65 64.99 185.67c-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160.01 60.56 27.12 149.48-159.79 175.36-215.11 92.8-12.87-19.19-21.39-41.59-24.46-66.19C-11.35 159.99 43.48 64.7 139.8 19.94c17.82-8.28 36.6-14.76 56.81-19.51 10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78zm267.78 0c-81.25 22.54-134.14 69.67-147.28 151.69-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160 60.56 27.13 149.48-159.78 175.36-215.1 92.8-12.87-19.19-21.39-41.59-24.46-66.19C256.43 159.99 311.25 64.7 407.58 19.94 425.4 11.66 444.17 5.18 464.39.43c10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78z"/></svg></div>

As a seasoned professional in the travel industry, attending the recent event was an enriching experience. The many networking opportunities, and discussions with industry leaders have provided invaluable insights and strategies to enhance our business practices. I'm excited to implement these learnings and continue driving success in our ever-evolving industry.

<br/><br/><strong>Maxine Frisk, American Express Global Business Travel Finland</strong></div>,


<div className="card"><div className="testimonial-quotation">  <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill="#F77441" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 379.51" 
style={{width:"26px",display:"block", paddingBottom:"33px"}}>
<path d="M212.27 33.98C131.02 56.52 78.14 103.65 64.99 185.67c-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160.01 60.56 27.12 149.48-159.79 175.36-215.11 92.8-12.87-19.19-21.39-41.59-24.46-66.19C-11.35 159.99 43.48 64.7 139.8 19.94c17.82-8.28 36.6-14.76 56.81-19.51 10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78zm267.78 0c-81.25 22.54-134.14 69.67-147.28 151.69-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160 60.56 27.13 149.48-159.78 175.36-215.1 92.8-12.87-19.19-21.39-41.59-24.46-66.19C256.43 159.99 311.25 64.7 407.58 19.94 425.4 11.66 444.17 5.18 464.39.43c10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78z"/></svg></div>

m&i Fest is a unique event where buyers and suppliers are brought together in a unique way. It felt like the sky was the limit. In one word, WOW. 

<br/><br/><strong>Matthias Dujardijn, Marriott Ghent & Residence Inn Ghent </strong></div>,


   
  ];

  

  const [currentSlide, setCurrentSlide] = useState(0);
  const [activeTab, setActiveTab] = useState('suppliers'); // Set the initial active tab to 'suppliers'

  const toggleAccordion = (tabName) => {
    setActiveTab(activeTab === tabName ? null : tabName);
  };

  const videoEl = useRef(null);

const attemptPlay = () => {
videoEl &&
  videoEl.current &&
  videoEl.current.play().catch(error => {
    console.error("Error attempting to play", error);
  });
};
const handleScroll = () => {
    const sectionElement = document.getElementById('scrollto');
    // If you want to scroll to a specific section by its ID

  // const percentageToScroll = 0.5;
  // const offset = window.innerHeight * percentageToScroll;
  // If you want to scroll a certain percentage down the page

  if (sectionElement) {
    sectionElement.scrollIntoView({ behavior: 'smooth' });
  }
};
 
const textRef = useRef(null);
const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  
    

useEffect(() => {

  
attemptPlay();

 

 
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };
  window.addEventListener("resize", handleWindowResize);
  return () => {
    window.removeEventListener("resize", handleWindowResize);

   
  };

  
}, []);

const gridData = [
  { id: 1, value: "7-10 RFPs", description: "Average number of enquiries given by buyers during m&i Fest" },
  { id: 2, value: "99/100", description: "Overall event score from our buyers" },
  { id: 3, value: "#1", description: "Our top buyer event" },
  { id: 4, value: "Top 1%", description: "Of global events according to Explori data" },
  { id: 5, value: "55k", description: "Industry connections created during the event" },
  { id: 6, value: "84%", description: "of exhibitors said m&i Fest offered great potential ROI" }
];

const footprintData = [
  { id: 1, value: "2.1M+", description: "Overall impressions on our social channels during the campaign" },
  { id: 2, value: "469,023", description: "Total press coverage reach" },
]






const [activeIndex, setActiveIndex] = useState(0);

const headlineimages = [
  
  [
   NYC
  ],
];



const headlinelinks = [
 
  ['https://www.nyctourism.com/']
];
const headlineheadings = ['Headline Partner'];


const eventimages = [
  
  [
   MoxyLogoBlack, WytheLogoBlack, PennyLogoBlack, ArloLogoBlack, HoxtonLogoBlack
  ],
];



const eventlinks = [
 
  ['https://www.marriott.com/en-us/hotels/nycxb-moxy-brooklyn-williamsburg/overview/', 'https://www.wythehotel.com/', 'https://www.penny-hotel.com/', 'https://arlohotels.com/williamsburg/?gad_source=1&gclid=Cj0KCQjwztOwBhD7ARIsAPDKnkAkYDb_Mq1gtOXwvvdPqHcqTyhRd79ai-25PXNGBSGkkEGkOQhrkygaAqeREALw_wcB&gclsrc=aw.ds', 'https://thehoxton.com/williamsburg/']
];
const eventheadings = ['Event Partners'];


return (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>m&i Fest Casestudy | m&i </title>
      <meta
        name="description"
        content="Elevate your business at the first ever MICE networking event in Brooklyn, NYC"
      />
      <meta
        property="og:url"
        content={
          "https://www.mi-forums.com/images/ographs/OG_image_Europe_Autumn_2022.png"
        }
      />
      <meta
        property="og:image"
        content={
          "https://www.mi-forums.com/images/ographs/OG_image_Europe_Autumn_2022.png"
        }
      />
    </Helmet>
    <div className="site-wrap">
      <div className="event-page twentyfour fest25 casestudy powder-bg">
        <div
          className="sprint-hero-slider" style={{
            backgroundImage: `url(${Header})`,
        }}>

          <div class="video-container">
        
      
      </div>

<div className="row no-gutters align-items-stretch">
                <div className="header-section-heading">
                <h4>A look back on</h4><br/>
                <img className="festlogo" src={Festlogo}/><br/>
                 <h4>Marrakesh 24 | 17-21 March</h4>
                 <a href="https://www.youtube.com/watch?v=vMn-wJ5nHC8&ab_channel=m%26i" target="_blank"> <img className="watchrecap"  src={Watchrecap}/><br/></a>
                 {/* <a href="#!" onClick={openModal} >
        {modal ? (

          
          <section className="modal__bg">
            <div className="modal__align">
              <div className="modal__content" modal={modal}>
                
                <div className="modal__video-align">
                  {videoLoading ? (
                    <div className="modal__spinner">
                    
                    </div>
                  ) : null}
                  <iframe
                              className="modal__video-style"
                              title="People of MI"
                    onLoad={spinner}
                    loading="lazy"
                    width="800"
                    height="500"
                    src="https://www.youtube.com/embed/vMn-wJ5nHC8?si=sUPLggiuJ9MvLZ2M"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </section>
        ) : null}
        <div className="peopleofmi-video-container">
        <img className="watchrecap"  src={Watchrecap}/><br/>
             </div></a> */}

                 
               
               
                </div>
            </div>


       
        </div>

        <Sticky>
          <div className="event-under-banner twentyfour raven-bg" id="scrollto">
            <div className="container">
              <div className="row no-gutters align-items-stretch">
                <div className=" ml-rg-auto event-sticky-text">
                  <h3>Want to attend m&i Fest 25?</h3>
                </div>
                <div className="ml-rg-auto event-buttons flex-wrap ">
                  <a href="/tell-me-more">
                    <span className=" btn btn-secondary transparent">
                      Tell me more
                    </span>
                  </a>
                  <a href="/signup">
                    <span className=" btn btn-secondary grey">
                    Register now
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Sticky>
     
        <div className="site-half eventInformationSection" >
          <div className="container">
            <div className="row no-gutters center-content">
           
            <div className="col-lg-10 mr-lg-auto py-5" style={{paddingRight:'20px'}}>
              {/* <img src={Pattern1}></img><br/><br/><br/> */}
              <h2>A trailblazing event for the MICE industry</h2>
              <br/>
              <p>
            Our first event of the year and our largest to date, m&i Fest Marrakesh 24, 
            was the ideal way to kick off our 2024 calendar. Combining the personalisation of our smaller 
            Forums with the extensive networking opportunities of an exhibition, this new format successfully brought together leaders
            in the MICE industry.
               </p>
            </div>

            </div>
          </div>
          <div className="anchorScroll" id="numbers"></div>
        </div>
       

        <div className="row splitrow explore" style={{backgroundColor:"#FBEFE7"}}>
                      <div class="column leftSide afternoons"  >
                      <div className='col-lg-12 ml-rg-auto py-5'>
                            
                      <div className="container">
          <div className="row no-gutters align-items-stretch">
            <div
              className="col-lg-7 ml-rg-auto "
            >


              <img src={Successfulvector} style={{padding:"0 0 8vh 0"}}></img>
              <br></br>
             
              <h1 className="site-section-heading font-secondary">
              A global MICE community
              </h1>
             
          
              <p
                  
                  className="mobilePadding-2"
                >
                500 industry leaders came together for over 7,500 meetings and a total of 55k connections in just 4 days.
                We introduced our new exhibitor Pavilions which provided dedicated spaces for suppliers,
                offering them maximum exposure and a unique platform to share their brand and destination stories.
                </p>
               
                </div>
          </div>
        </div>
                          
                          
                          </div>

          </div>

              <div className="column rightSide afternoons" style={{ backgroundImage: `url(${Image1})` }}>
                          <div className='col-lg-12 ml-rg-auto py-5'>
                        
                        
                          </div>
              </div>
      </div>

      <div className="row splitrow celebrate" style={{backgroundColor:"#352E35"}}>
                      <div class="column leftSide celebrate" style={{ backgroundImage: `url(${Image2})` }}>
                      <div className='col-lg-12 ml-rg-auto py-5'>
                            
                          </div>

          </div>

          <div className="column rightSide celebrate powder-bg">
          <div className='col-lg-12 ml-rg-auto py-5'>
                            
                            <div className="container">
                <div className="row no-gutters align-items-stretch">
                  <div
                    className="col-lg-7 ml-rg-auto "
                  >
      
      
                    <img src={Outsidevector} style={{padding:"0 0 8vh 0"}}></img>
                    <br></br>
                   
                    <h1 className="site-section-heading font-secondary">
                Outside the meeting room
                    </h1>
                   
                
                    <p
                        
                        className="mobilePadding-2"
                      >
                    Our Pathways allowed delegates to connect while exploring Marrakesh's many attractions,
                    including cultural landmarks like Bahia Palace and Medersa Ben Youssef, as well as engaging
                    in thrilling activities such as jet skiing and buggy tours.
                    </p>
                     
                      </div>
                </div>
              </div>
                                
                                
                                </div>
      </div>

     
     
              {/* <button onClick={prevSlide}>Previous</button>
      <button onClick={nextSlide}>Next</button> */}
      </div>

      <div className="row splitrow explore nights" style={{backgroundColor:"#121A36"}}>
                      <div class="column leftSide afternoons"  >
                      <div className='col-lg-12 ml-rg-auto py-5'>
                            
                      <div className="container">
          <div className="row no-gutters align-items-stretch">
            <div
              className="col-lg-7 ml-rg-auto "
            >


              <img src={Networkingvector} style={{padding:"0 0 8vh 0"}}></img>
              <br></br>
             
              <h1 className="site-section-heading font-secondary">
          The networking continued
              </h1>
             
          
              <p
                  
                  className="mobilePadding-2"
                >
           Evening events held at Mövenpick, Barceló Palmeraie Marrakesh, Yes We Camp, and Fairmont Royal Palm Marrakesh were both exciting
           and memorable, creating the perfect settings for connection and memory-making.
            </p>
               
                </div>
          </div>
        </div>
                          
                          
                          </div>

          </div>

              <div className="column rightSide afternoons" style={{ backgroundImage: `url(${Image3})` }}>
                          <div className='col-lg-12 ml-rg-auto py-5'>
                        
                        
                          </div>
              </div>
      </div>

      <div className="site-half block whattoexpect" style={{ paddingBottom: "5rem" }}>
          <div className="img-bg-1">
            <img alt={" "} src={Image4} className={"network"} style={{width:"430px"}} />
          </div>
          <div className="container">
            <div className="row no-gutters align-items-stretch">
              <div
                className="col-lg-5 ml-rg-auto "
              >
                  <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill="#F77441" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 379.51" 
               style={{width:"26px",display:"block", paddingBottom:"33px"}}>
    <path d="M212.27 33.98C131.02 56.52 78.14 103.65 64.99 185.67c-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160.01 60.56 27.12 149.48-159.79 175.36-215.11 92.8-12.87-19.19-21.39-41.59-24.46-66.19C-11.35 159.99 43.48 64.7 139.8 19.94c17.82-8.28 36.6-14.76 56.81-19.51 10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78zm267.78 0c-81.25 22.54-134.14 69.67-147.28 151.69-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160 60.56 27.13 149.48-159.78 175.36-215.1 92.8-12.87-19.19-21.39-41.59-24.46-66.19C256.43 159.99 311.25 64.7 407.58 19.94 425.4 11.66 444.17 5.18 464.39.43c10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78z"/></svg>
                <h2 className="site-section-heading font-secondary">
                I can confidently say that we haven’t had a buzz like we had at m&i Fest for a long time. It was as if we were watching the industry spring back to life!
                </h2>
                <p
                    style={{ paddingRight: "20px" }}
                    className="mobilePadding-2"
                  >
             I had a string of delegates approach me to say that they’d never attended an event like it and that the level of meetings was unmatched. And this is further proved by the fantastic feedback we’ve had through the Wyred app – it’s the most positive to date. I’m already looking forward to continuing the m&i Fest legacy in next year’s edition. 
             <br/><br/>
             <strong>- Richard Barnes, CEO Worldwide Events</strong>
                  </p>
              </div>
            </div>
          </div>
        </div>

        <div className="site-half sampleProgramme memories raven-bg">
        <h1>The memories</h1>
       
          <div className="container">
            <div className="row no-gutters align-items-stretch">
             
           
            <div
              className="col-lg-12 ml-rg-auto py-5"
            >
               <div className="programmeItem">
                 <img
              alt={" "}
              src={Memories}
            ></img><br/>
             <a href="https://photos.mi-forums.com/Mi-Fest-Marrakesh-24 ">
                    <span className=" btn btn-secondary white">
                    View the gallery
                    </span>
                  </a>
          
              </div>
              </div>

             

            
            
            </div>
          </div>
        </div>




    
       
        



    


        <div className="site-half powder-bg" >
            <div className="container">
            <h2 style={{textAlign:"center"}}>m&i Fest by numbers</h2>
              <div className="grid-container">
                
      {gridData.map((item) => (
        <div key={item.id} className="grid-item">
          <h1>{item.value}</h1>
          <p>{item.description}</p>
        </div>
      ))}
    </div>
              
            </div>
           
          </div>

          <div className="site-half pressSection">
        <h2>Hot off the press</h2>
          <div className="container">
            <div className="row no-gutters align-items-stretch">
             
           
            <div
              className="col-lg-4 ml-rg-auto py-5"
            >
               <div className="programmeItem">
               <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill="#F77441" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 379.51" 
               style={{width:"26px",display:"block",margin:"auto", paddingBottom:"33px"}}>
    <path d="M212.27 33.98C131.02 56.52 78.14 103.65 64.99 185.67c-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160.01 60.56 27.12 149.48-159.79 175.36-215.11 92.8-12.87-19.19-21.39-41.59-24.46-66.19C-11.35 159.99 43.48 64.7 139.8 19.94c17.82-8.28 36.6-14.76 56.81-19.51 10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78zm267.78 0c-81.25 22.54-134.14 69.67-147.28 151.69-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160 60.56 27.13 149.48-159.78 175.36-215.1 92.8-12.87-19.19-21.39-41.59-24.46-66.19C256.43 159.99 311.25 64.7 407.58 19.94 425.4 11.66 444.17 5.18 464.39.43c10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78z"/></svg>
    <a href="https://kongres-magazine.eu/2024/03/mi-fest-ushering-in-a-new-era-for-trade-shows/">
                 <img
              alt={" "}
              src={Kongres}
              className={"sampleThumbnails"}
            ></img></a>
             
             <p className="itemCaption">"m&i Fest ushered in a new era for trade shows. It was a masterclass in pairing effective in-person meetings with unparalleled destination tours"</p>
             <h1 className="itemHeading">Kongres Magazine</h1>
              </div>
              </div>

            
              <div
              className="col-lg-4 ml-rg-auto py-5"
            >
                <div className="programmeItem">
                <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill="#F77441" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 379.51" 
               style={{width:"26px",display:"block",margin:"auto", paddingBottom:"33px"}}>
    <path d="M212.27 33.98C131.02 56.52 78.14 103.65 64.99 185.67c-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160.01 60.56 27.12 149.48-159.79 175.36-215.11 92.8-12.87-19.19-21.39-41.59-24.46-66.19C-11.35 159.99 43.48 64.7 139.8 19.94c17.82-8.28 36.6-14.76 56.81-19.51 10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78zm267.78 0c-81.25 22.54-134.14 69.67-147.28 151.69-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160 60.56 27.13 149.48-159.78 175.36-215.1 92.8-12.87-19.19-21.39-41.59-24.46-66.19C256.43 159.99 311.25 64.7 407.58 19.94 425.4 11.66 444.17 5.18 464.39.43c10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78z"/></svg>
                 <img
              alt={" "}
              src={Travel}
              className={"sampleThumbnails"}
            ></img>
          
             <p className="itemCaption">"m&i Fest Revolutionizes MICE Trade Shows with New Event Format in Marrakesh"</p>
             <h1 className="itemHeading">Travel Weekly - Mena</h1>
              </div>
              </div>

             
              <div
              className="col-lg-4 ml-rg-auto py-5"
            >
               <div className="programmeItem">
               <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill="#F77441" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 379.51" 
               style={{width:"26px",display:"block",margin:"auto", paddingBottom:"33px"}}>
    <path d="M212.27 33.98C131.02 56.52 78.14 103.65 64.99 185.67c-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160.01 60.56 27.12 149.48-159.79 175.36-215.11 92.8-12.87-19.19-21.39-41.59-24.46-66.19C-11.35 159.99 43.48 64.7 139.8 19.94c17.82-8.28 36.6-14.76 56.81-19.51 10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78zm267.78 0c-81.25 22.54-134.14 69.67-147.28 151.69-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160 60.56 27.13 149.48-159.78 175.36-215.1 92.8-12.87-19.19-21.39-41.59-24.46-66.19C256.43 159.99 311.25 64.7 407.58 19.94 425.4 11.66 444.17 5.18 464.39.43c10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78z"/></svg>
    <a href="
https://www.c-mw.net/all-on-board-the-mis-mice-express-in-marrakesh-24/">
                 <img
              alt={" "}
              src={CMW}
              className={"sampleThumbnails"}
            ></img></a>
           
             <p className="itemCaption">“m&i Fest is their blueprint for their future of events - a modern caravanserai for conferencing. It is buyer-led, meaning that the organisers have an intuitive ‘ear to the ground’ for what they want”. </p>
             <h1 className="itemHeading">CMW</h1>
              </div>
              </div>

            </div>
          </div>
        </div>

     

        <div className="site-half bambi-bg" >
            <div className="container">
            <h2 style={{textAlign:"center"}}>The event footprint</h2>
              <div className="grid-container footprint">
                
      {footprintData.map((item) => (
        <div key={item.id} className="grid-item">
          <h1>{item.value}</h1>
          <p>{item.description}</p>
        </div>
      ))}
    </div>
            </div>
          </div>

          <div className="site-half powder-bg whoattended" >
            <div className="container">
            <h2 style={{textAlign:"center"}}>Who attended</h2>
           
            </div>
          </div>

          <div className="row splitrow explore nights breakdown" style={{backgroundColor:"#FBEFE7"}}>
                      <div class="column leftSide afternoons"  >
                      <div className='col-lg-12 ml-rg-auto py-5'>
                            
                     
<div className="container">
           
                  <h4
                                        className='site-section-heading font-secondary '
                                        style={{ textDecoration: 'underline' }}
                                    >
                                     Supplier country breakdown
                                    </h4>
                <p
                    style={{ paddingRight: "20px" }}
                    className="mobilePadding-2"
                  >
             <ul>

              <li><span >83%</span> Europe</li>
              <hr></hr>
              <li><span >12%</span> Americas</li>
            
              <hr></hr>
              <li><span >4%</span> ROW (rest of the world)</li>
              <hr></hr>
              
             </ul>
            
                  </p>
<br/><br/>
                  <h4
                                        className='site-section-heading font-secondary '
                                        style={{ textDecoration: 'underline' }}
                                    >
                                     Supplier type
                                    </h4>
                                    <p
                    style={{ paddingRight: "20px" }}
                    className="mobilePadding-2"
                  >
             <ul>

              <li><span >70%</span> Hotels</li>
              <hr></hr>
              <li><span >10%</span> DMC's</li>
            
              <hr></hr>
              <li><span >20%</span> Destinations</li>
              <hr></hr>
              
             </ul>
            
                  </p>
             
          
               
                
        </div>
                          
                          
                          </div>

          </div>

              <div className="column rightSide afternoons" style={{backgroundColor:"#fff"}}>
                          <div className='col-lg-12 ml-rg-auto py-5'>
                          <div className="container">
           
           <h4
                                 className='site-section-heading font-secondary '
                                 style={{ textDecoration: 'underline' }}
                             >
                              Buyer country breakdown
                             </h4>
         <p
             style={{ paddingRight: "20px" }}
             className="mobilePadding-2"
           >
      <ul>

       <li><span >83%</span> Europe</li>
       <hr></hr>
       <li><span >12%</span> Americas</li>
     
       <hr></hr>
       <li><span >4%</span> ROW (rest of the world)</li>
       <hr></hr>
       
      </ul>
     
           </p>
<br/><br/>
           <h4
                                 className='site-section-heading font-secondary '
                                 style={{ textDecoration: 'underline' }}
                             >
                              Buyer type
                             </h4>
                             <p
             style={{ paddingRight: "20px" }}
             className="mobilePadding-2"
           >
      <ul>

       <li><span >62%</span> Agency</li>
       <hr></hr>
       <li><span >28%</span> Corporate</li>
     
       <hr></hr>
       <li><span >10%</span> Independents and associations</li>
       <hr></hr>
       
      </ul>
     
           </p>

           <br/><br/>
           <h4
                                 className='site-section-heading font-secondary '
                                 style={{ textDecoration: 'underline' }}
                             >
                              Buyer purchasing power
                             </h4>
                             <p
             style={{ paddingRight: "20px" }}
             className="mobilePadding-2"
           >
      <ul>

       <li style={{paddingRight:"52%"}}><span style={{marginRight:"50px"}}>€2.6m</span> Average annual budget per buyer per event</li>
       <hr></hr>
     
       
      </ul>
     
           </p>
      
   
        
         
 </div>
                        
                          </div>
              </div>
      </div>

     





         

      <div className="site-half eventInformationSection testimonialsection powder-bg" >
            <div className="container">
              <div className="row no-gutters center-content">
             
              <div className="col-lg-10 mr-lg-auto py-5" style={{paddingRight:'20px'}}>
              <h3
                                        className='site-section-heading font-secondary '
                                        style={{ textDecoration: 'underline' }}
                                    >
                                       What our community is saying
                                    </h3>
                <br/>
              
              </div>

              </div>
            </div>
            <Carousel items={cards} />
           
          </div>


        
    


         
    

      

      


        
       
       
        <div
          className="cta-banner"
          style={{ backgroundImage: `url(${CTA})` }}
        >
          <div className="container">
            <div className="row no-gutters align-items-stretch">
              <div className="col-lg-12 ml-rg-auto py-5 laptop">
                <div className="cta-content">
                <div
                  className="footer-section-heading "
               
                >
                <h3>m&i Marrakesh 25</h3>
                 <h1>Elevate your MICE business in 2025.   </h1>
                 <h3 className="caption">Save your space today. </h3>
                </div>
                  <a href="/signup">
                    <span className=" btn btn-primary">
                    See if you qualify
                    </span>
                  </a>
                  <br/><br/><br/>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      


      
      </div>
    </div>
  </>
);
};


export default FestCaseStudy;
