import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import '../assets/scss/style.scss';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import HeroBanner1920 from '../assets/images/banners/Event_Calendar_Hero_1920.png';
import HeroBanner1024 from '../assets/images/banners/Event_Calendar_Hero_1024.png';
import HeroBanner766 from '../assets/images/banners/Event_Calendar_Hero_766.png';
import HeroBanner320 from '../assets/images/banners/Event_Calendar_Hero_320.png';
import CalendarCharacters from '../assets/images/calendarcharacter.svg';

const Calendar = () => {
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const heroBannerUrl =
    windowWidth <= 479
      ? HeroBanner320
      : windowWidth <= 768
      ? HeroBanner766
      : windowWidth <= 1024
      ? HeroBanner1024
      : HeroBanner1920;

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>m&i | Upcoming MICE Networking Opportunities & Events</title>
        <meta
          name='description'
          content='Explore the m&i calendar for global MICE events and discover our upcoming MICE networking opportunities in Europe and the Americas.'
        />
        <meta
          name='tags'
          content='Explore the m&i calendar for global MICE events and discover our upcoming MICE networking opportunities in Europe and the Americas.'
        />
      </Helmet>
      <div className='site-wrap calendar raven-bg'>
        <div className='site-half newHeader raven-bg'>
          <div className='container'>
            <div className='row no-gutters center-content'>
              <div className='col-lg-10 mr-lg-auto py-5' style={{ paddingRight: '10%' }}>
                <h2></h2>

                <h3>Looking for your next MICE event?</h3>
                <h1>Look no further.</h1>
              </div>
            </div>
          </div>
          <div className='anchorScroll' id='numbers'></div>
        </div>

        <div className='site-half itinerarySection' style={{ paddingTop: '0' }}>
          <div className='container'>
            <div className='row no-gutters align-items-stretch'>
              <div className='col-lg-12 ml-rg-auto '>
                <br />

                <Tabs defaultTab='three'>
                  <div className={`sticky-wrapper${isSticky ? ' sticky' : ''}`} ref={ref}>
                    <TabList className='sticky-inner calendar'>
                      <Tab tabFor='two' className='eventTabs'>
                        2024 events
                      </Tab>
                      <Tab tabFor='one' className='eventTabs'>
                        2025 events
                      </Tab>
                      <Tab tabFor='three' className='eventTabs all'>
                        all
                      </Tab>
                    </TabList>
                  </div>
                  <TabPanel tabId='one'>
                    {/* 2025 EVENTS */}
                    <table className='eventTable'>
                    <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/mi-flagship-brooklyn-25';
                        }}
                      >
                        <td className='eventName'> m&i Flagship Brooklyn | American buyers   </td>
                        <td className='date'>9-12 Feb </td>
                        <td className='location'>United States</td>
                      </tr>

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/mi-fest-25';
                        }}
                      >
                        <td className='eventName'> m&i Fest Global, Marrakech    	</td>
                        <td className='date'>16-20 Mar </td>
                        <td className='location'>Morocco</td>
                      </tr>

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/luxe-lake-louise-25';
                        }}
                      >
                        <td className='eventName'>Luxe Lake Louise | American buyers </td>
                        <td className='date'>24-27 Mar </td>
                        <td className='location'>Canada</td>
                      </tr>

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/luxe-mykonos-25';
                        }}
                      >
                        <td className='eventName'>Luxe Mykonos | UK & EU buyers 	</td>
                        <td className='date'>16-19 May </td>
                        <td className='location'>Greece</td>
                      </tr>

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/mi-healthcare-gothenburg-25';
                        }}
                      >
                        <td className='eventName'> m&i Healthcare Gothenburg | UK & EU buyers</td>
                        <td className='date'>29 Jun - 2 Jul </td>
                        <td className='location'>Sweden</td>
                      </tr>

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/mi-zagreb-25';
                        }}
                      >
                        <td className='eventName'> m&i Express Zagreb | UK & EU buyers </td>
                        <td className='date'>27-29 Aug </td>
                        <td className='location'>Croatia</td>
                      </tr>

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/mi-private-davos-25';
                        }}
                      >
                        <td className='eventName'> m&i Private Davos | UK & EU buyers  </td>
                        <td className='date'>28 Sep - 1 Oct </td>
                        <td className='location'>Switzerland</td>
                      </tr>

                   

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/mi-flagship-antalya-25';
                        }}
                      >
                        <td className='eventName'> m&i Flagship Antalya | UK & EU buyers   </td>
                        <td className='date'>12-16 Oct </td>
                        <td className='location'>Türkiye</td>
                      </tr>

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/mi-flagship-dubai-25';
                        }}
                      >
                        <td className='eventName'> m&i Flagship Dubai | UK & EU buyers   </td>
                        <td className='date'>30 Nov - 4 Dec </td>
                        <td className='location'>UAE</td>
                      </tr>
                    </table>
                  </TabPanel>
                  <TabPanel tabId='two'>
                    {/* 2024 EVENTS */}
                    <table className='eventTable'>
                      {/*  */}

                   
                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/mi-lisbon-24';
                        }}
                      >
                        <td className='eventName'> m&i Lisbon 24 </td>
                        <td className='date'>25-28 Aug</td>
                        <td className='location'>Portugal</td>
                      </tr>

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/mi-sardinia-24';
                        }}
                      >
                        <td className='eventName'>m&i Sardinia 24 </td>
                        <td className='date'>25-29 Sep</td>
                        <td className='location'>Italy</td>
                      </tr>

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/mi-luxe-bodrum-24';
                        }}
                      >
                        <td className='eventName'>Luxe Bodrum </td>
                        <td className='date'>2-5 Oct</td>
                        <td className='location'>Türkiye</td>
                      </tr>

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/mi-vip-costa-mujeres-24';
                        }}
                      >
                        <td className='eventName'>m&i VIP Costa Mujeres 24</td>
                        <td className='date'>17-20 Nov</td>
                        <td className='location'>Mexico</td>
                      </tr>
                    </table>
                  </TabPanel>
                  <TabPanel tabId='three'>
                    <table className='eventTable'>
                  
                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/mi-lisbon-24';
                        }}
                      >
                         <td className='eventName'> m&i Lisbon 24 </td>
                        <td className='date'>25-28 Aug</td>
                        <td className='location'>Portugal</td>
                      </tr>

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/mi-sardinia-24';
                        }}
                      >
                        <td className='eventName'>m&i Sardinia 24 </td>
                        <td className='date'>25-29 Sep</td>
                        <td className='location'>Italy</td>
                      </tr>

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/mi-luxe-bodrum-24';
                        }}
                      >
                        <td className='eventName'>Luxe Bodrum </td>
                        <td className='date'>2-5 Oct</td>
                        <td className='location'>Türkiye</td>
                      </tr>

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/mi-vip-costa-mujeres-24';
                        }}
                      >
                          <td className='eventName'>m&i VIP Costa Mujeres 24</td>
                        <td className='date'>17-20 Nov</td>
                        <td className='location'>Mexico</td>
                      </tr>

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/mi-flagship-brooklyn-25';
                        }}
                      >
                        <td className='eventName'> m&i Flagship Brooklyn | American buyers   </td>
                        <td className='date'>9-12 Feb </td>
                        <td className='location'>United States</td>
                      </tr>

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/mi-fest-25';
                        }}
                      >
                        <td className='eventName'> m&i Fest Global, Marrakech    	</td>
                        <td className='date'>16-20 Mar </td>
                        <td className='location'>Morocco</td>
                      </tr>

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/luxe-lake-louise-25';
                        }}
                      >
                        <td className='eventName'>Luxe Lake Louise | American buyers </td>
                        <td className='date'>24-27 Mar </td>
                        <td className='location'>Canada</td>
                      </tr>

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/luxe-mykonos-25';
                        }}
                      >
                        <td className='eventName'>Luxe Mykonos | UK & EU buyers 	</td>
                        <td className='date'>16-19 May </td>
                        <td className='location'>Greece</td>
                      </tr>

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/mi-healthcare-gothenburg-25';
                        }}
                      >
                        <td className='eventName'> m&i Healthcare Gothenburg | UK & EU buyers</td>
                        <td className='date'>29 Jun - 2 Jul </td>
                        <td className='location'>Sweden</td>
                      </tr>

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/mi-zagreb-25';
                        }}
                      >
                        <td className='eventName'> m&i Express Zagreb | UK & EU buyers </td>
                        <td className='date'>27-29 Aug </td>
                        <td className='location'>Croatia</td>
                      </tr>

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/mi-private-davos-25';
                        }}
                      >
                        <td className='eventName'> m&i Private Davos | UK & EU buyers  </td>
                        <td className='date'>28 Sep - 1 Oct </td>
                        <td className='location'>Switzerland</td>
                      </tr>

                   

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/mi-flagship-antalya-25';
                        }}
                      >
                        <td className='eventName'> m&i Flagship Antalya | UK & EU buyers   </td>
                        <td className='date'>12-16 Oct </td>
                        <td className='location'>Türkiye</td>
                      </tr>

                      <tr
                        className='eventRow'
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href = '/mi-flagship-dubai-25';
                        }}
                      >
                        <td className='eventName'> m&i Flagship Dubai | UK & EU buyers   </td>
                        <td className='date'>30 Nov - 4 Dec </td>
                        <td className='location'>UAE</td>
                      </tr>
                    </table>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="wyred-footer-slider">
          <div className="container">
            <div className="row no-gutters align-items-stretch">
              <div
                className="col-lg-8 ml-rg-auto py-5 laptop"
                style={{ paddingLeft: "100px" }}
              >
                <h2 style={{ fontSize: "36px" }}>
                  connect anytime, anywhere <br />
                  with{" "}
                  <span className="blue-underlined">wyred world on demand</span>
                </h2>
                <br />

                <p
                  style={{
                    lineHeight: "28px",
                    paddingBottom: "40px",
                  }}
                >
                  Enjoy the benefits of a virtual event 24/7, 365 days a year
                  <br /> with our online digital networking platform.
                </p>
                <a href="/on-demand">
                  <span className=" btn btn-cta">find out more</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-10 ml-rg-auto py-5">
            <div className="img-bg-1">
              <img alt={" "} src={WyredWorldLogo} className={"wyredLogo"}></img>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Calendar;
