import React from "react"
import "../assets/scss/style.scss"

const SupplierPayment = () => {
  return (
    <>
      <div class="form-container">
        <iframe
          height="100%"
          width="100%"
          title="SupplierPayment"
          onload="window.parent.scrollTo(0,0)"
          id="ft1615275787167-337-95"
          allowtransparency="true"
          src="https://efress.formtitan.com/ft9137dbb91615219864369_copy"
          frameborder="0"
        ></iframe>
      </div>
    </>
  )
}

export default SupplierPayment
