import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import enableInlineVideo from 'iphone-inline-video';
import Carousel from "../../components/elements/Carousel"
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import Sticky from "react-sticky-el";
import "../../assets/scss/style.scss";
import Connect from "../../assets/images/events/2025/brooklyn/Connect.png";
import Experience from "../../assets/images/events/2025/brooklyn/Experience.png";
import Enjoy from "../../assets/images/events/2025/brooklyn/Enjoy.png";
import CTA from "../../assets/images/events/2025/brooklyn/CTA.png";
import Spotlight from "../../assets/images/events/2025/brooklyn/Spotlight.png";
import Header from "../../assets/images/events/2025/brooklyn/Header.png";
import Arlo from "../../assets/images/events/2025/brooklyn/Arlo.png"
import Penny from "../../assets/images/events/2025/brooklyn/Penny.jpg"
import Moxy from "../../assets/images/events/2025/brooklyn/Moxy.jpg"
import Hoxton from "../../assets/images/events/2025/brooklyn/Hoxton.png"
import Wythe from "../../assets/images/events/2025/brooklyn/Wythe.png"
import ArloLogo from "../../assets/images/events/partner_logos/arlo.png"
import PennyLogo from "../../assets/images/events/partner_logos/penny.png"
import MoxyLogo from "../../assets/images/events/partner_logos/moxy.png"
import HoxtonLogo from "../../assets/images/events/partner_logos/hoxton.png"
import WytheLogo from "../../assets/images/events/partner_logos/wythehotel.png"
import ArloLogoBlack from "../../assets/images/events/partner_logos/arlo-logo-black.png"
import MoxyLogoBlack from "../../assets/images/events/partner_logos/moxy-logo-black.png"
import WytheLogoBlack from "../../assets/images/events/partner_logos/wythe-logo-black.png"
import HoxtonLogoBlack from "../../assets/images/events/partner_logos/hoxton-logo-black.png"
import PennyLogoBlack from "../../assets/images/events/partner_logos/penny-logo-black.png"
import NYC from "../../assets/images/events/partner_logos/newyorkcity.svg"

const Brooklyn = () => {

  const [currentSlide, setCurrentSlide] = useState(0);
  const [activeTab, setActiveTab] = useState('suppliers'); // Set the initial active tab to 'suppliers'

  const toggleAccordion = (tabName) => {
    setActiveTab(activeTab === tabName ? null : tabName);
  };

  const videoEl = useRef(null);

const attemptPlay = () => {
videoEl &&
  videoEl.current &&
  videoEl.current.play().catch(error => {
    console.error("Error attempting to play", error);
  });
};
const handleScroll = () => {
    const sectionElement = document.getElementById('scrollto');
    // If you want to scroll to a specific section by its ID

  // const percentageToScroll = 0.5;
  // const offset = window.innerHeight * percentageToScroll;
  // If you want to scroll a certain percentage down the page

  if (sectionElement) {
    sectionElement.scrollIntoView({ behavior: 'smooth' });
  }
};
 
const textRef = useRef(null);
const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  
    

useEffect(() => {

  
attemptPlay();

 

 
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };
  window.addEventListener("resize", handleWindowResize);
  return () => {
    window.removeEventListener("resize", handleWindowResize);

   
  };

  
}, []);

const slides = [
  {
    backgroundImage: `url(${Arlo})`,
    title: 'Accommodation and meeting venue',
    imageSrc: ArloLogo,
    content: 'Formerly The Williamsburg Hotel, home of the iconic Water Tower Bar, the Arlo promises a stylish stay complete with a rooftop bar offering iconic views and customisable event spaces. ',
    website: 'https://arlohotels.com/williamsburg/?gad_source=1&gclid=Cj0KCQjwztOwBhD7ARIsAPDKnkAkYDb_Mq1gtOXwvvdPqHcqTyhRd79ai-25PXNGBSGkkEGkOQhrkygaAqeREALw_wcB&gclsrc=aw.ds',
    width: '200px', 
  },
  {
    backgroundImage: `url(${Penny})`,
    title: 'Accommodation venue',
    imageSrc: PennyLogo,
    content: 'A stylish property boasting a 700 sq ft. terrace as part of its meetings and events space totalling 4,500 sq ft. in addition to a popular cocktail bar complimented by a selection of local artwork to give each room a homely feel.  ',
    website: 'https://www.penny-hotel.com/',
    width: '45vh',
  },
  {
    backgroundImage: `url(${Moxy})`,
    title: 'Accommodation venue',
    imageSrc: MoxyLogo,
    content: 'A 216-room boutique hotel with a design aesthetic that fits seamlessly into the surrounding neighbourhood, The Moxy houses 13,000 sq ft. of customisable event space and a large garden for flexible co-working. ',
    website: 'https://www.marriott.com/en-us/hotels/nycxb-moxy-brooklyn-williamsburg/overview/',
    width: '32vh',
  },
  {
    backgroundImage: `url(${Hoxton})`,
    title: 'Accommodation venue',
    imageSrc: HoxtonLogo,
    content: 'Situated on the grounds of a former factory, this chic 175-room hotel is conveniently located just a block from the East River and houses a selection of award-winning eateries.',
    website: 'https://thehoxton.com/williamsburg/',
    width: '45vh',
  },
  {
    backgroundImage: `url(${Wythe})`,
    title: 'Accommodation venue',
    imageSrc: WytheLogo,
    content: 'Housed in a converted 100-year-old factory, the 69-room Wythe Hotel is located on the Brooklyn waterfront with unmatched views of New York’s famous skyline and 6,000 sq ft. of event space.',
    website: 'https://www.wythehotel.com/',
    width: '45vh',
  },
];

const nextSlide = () => {
  setCurrentSlide((currentSlide + 1) % slides.length);
};


useEffect(() => {
  const intervalId = setInterval(() => {
    setCurrentSlide((currentSlide + 1) % slides.length);
  }, 5000);

  return () => clearInterval(intervalId);
}, [currentSlide, slides.length]);

const prevSlide = () => {
  setCurrentSlide((currentSlide - 1 + slides.length) % slides.length);
};

const goToSlide = (index) => {
  setCurrentSlide(index);
};


const headlineimages = [
  
  [
   NYC
  ],
];



const headlinelinks = [
 
  ['https://www.nyctourism.com/']
];
const headlineheadings = ['Headline Partner'];


const eventimages = [
  
  [
   MoxyLogoBlack, WytheLogoBlack, PennyLogoBlack, ArloLogoBlack, HoxtonLogoBlack
  ],
];



const eventlinks = [
 
  ['https://www.marriott.com/en-us/hotels/nycxb-moxy-brooklyn-williamsburg/overview/', 'https://www.wythehotel.com/', 'https://www.penny-hotel.com/', 'https://arlohotels.com/williamsburg/?gad_source=1&gclid=Cj0KCQjwztOwBhD7ARIsAPDKnkAkYDb_Mq1gtOXwvvdPqHcqTyhRd79ai-25PXNGBSGkkEGkOQhrkygaAqeREALw_wcB&gclsrc=aw.ds', 'https://thehoxton.com/williamsburg/']
];
const eventheadings = ['Event Partners'];


return (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>m&i Flagship Brooklyn | American buyers </title>
      <meta
        name="description"
        content="Elevate your business at the first ever MICE networking event in Brooklyn, NYC"
      />
      <meta
        property="og:url"
        content={
          "https://www.mi-forums.com/images/ographs/OG_image_Europe_Autumn_2022.png"
        }
      />
      <meta
        property="og:image"
        content={
          "https://www.mi-forums.com/images/ographs/OG_image_Europe_Autumn_2022.png"
        }
      />
    </Helmet>
    <div className="site-wrap">
      <div className="event-page twentyfour lakelouise powder-bg">
        <div
          className="sprint-hero-slider" style={{
          //  backgroundImage: `url(${Header})`,
        }}>

<div class="video-container">
    <iframe class="hero-background-video" 
            src="https://www.youtube.com/embed/kCVmwDaJ1l4?rel=0&amp;autoplay=1&amp;mute=1&amp;playsinline=1&amp;controls=0&amp;showinfo=0&amp;loop=1&amp;playlist=kCVmwDaJ1l4" 
            frameborder="0" 
            allowfullscreen="allowfullscreen">
    </iframe>
</div>

      
    

<div className="row no-gutters align-items-stretch">
                <div className="header-section-heading">
                {/* <h3>Flagship</h3> */}
                 <h1>m&i Flagship Brooklyn </h1>
                 <h3>American buyers   </h3>
                 <h4>9-12 Feb | USA   </h4>
                 <div className="scroll-arrow" onClick={handleScroll} >
   <svg width="70" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" fill="#E0C7B2">
<g><path d="M500,10"/><path d="M500,990L108,598h784L500,990z"/></g>
</svg>
  </div>
               
                </div>
            </div>


       
        </div>

        <Sticky>
          <div className="event-under-banner twentyfour raven-bg" id="scrollto">
            <div className="container">
              <div className="row no-gutters align-items-stretch">
                <div className=" ml-rg-auto event-sticky-text">
                  <h3>Love the sound of this event?</h3>
                </div>
                <div className="ml-rg-auto event-buttons flex-wrap ">
                  <a href="/tell-me-more">
                    <span className=" btn btn-secondary transparent">
                      Tell me more
                    </span>
                  </a>
                  <a href="/signup">
                    <span className=" btn btn-secondary grey">
                    Register now
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Sticky>
     
        <div className="site-half eventInformationSection powder-bg" >
          <div className="container">
            <div className="row no-gutters center-content">
           
            <div className="col-lg-10 mr-lg-auto py-5" style={{paddingRight:'20px'}}>
              <h2>Elevate your business at the first ever MICE showcase event in Brooklyn, NYC</h2>
              <br/>
              <p>
             

              Our Americas Flagship Forum promises to provide suppliers with unbeatable exposure to the best
American MICE buyers
through a day-to-night networking programme. While
American buyers
will have the chance to not only explore Brooklyn’s burgeoning meetings and events potential, but also New York’s established MICE infrastructure, all while connecting with America’s best properties, DMCs and CVBs.
              </p>
            </div>

            </div>
          </div>
          <div className="anchorScroll" id="numbers"></div>
        </div>

        <div className="numbers-section eventNumbers powder-bg">
          <div className="container">
            <div className="row no-gutters align-items-stretch">
              <div
                className="col-lg-12 ml-rg-auto py-4 laptop"
                style={{ color: "#fff" }}
              >
                <div className="container">
                  <div className="inNumbers">
                  
                    <ul>
                      <li>
                        <div className="numbers-caption ">
                          <h1 className="eventNumbers raven">3</h1>
                          <p>Days</p>
                        </div>
                      </li>
                      <li>
                        <div className="numbers-caption">
                          <h1 className="eventNumbers raven">40</h1>
                          <p>1-2-1 Meetings</p>
                        </div>
                      </li>
                      <li>
                        {" "}
                        <div className="numbers-caption">
                          <h1 className="eventNumbers raven">90</h1>
                          <p>Supplier tables</p>
                        </div>
                      </li>
                      <li>
                        <div className="numbers-caption">
                          <h1 className="eventNumbers raven">100</h1>
                          <p>American buyers</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="anchorScroll" id="spotlight"></div>
          </div>
        </div>


       
        <div className="row splitrow explore" style={{backgroundColor:"#E8F0FA"}}>
                      <div class="column leftSide afternoons"  >
                      <div className='col-lg-12 ml-rg-auto py-5'>
                            
                      <div className="container">
          <div className="row no-gutters align-items-stretch">
            <div
              className="col-lg-7 ml-rg-auto "
            >
             
              <h1 className="site-section-heading font-secondary">
              Spotlight on Brooklyn
              </h1>
             
          
              <p
                  
                  className="mobilePadding-2"
                >
       Brooklyn is fast becoming an internationally recognised MICE destination with a host of new MICE venues. A stone’s throw from New York’s central business district, you’re never far from a hard-brand hotel, state-of-the-art meeting room or convention centre.  


                  <br />
                  <br />
                  And with new hotels emerging all the time including our accommodation and meetings venue, Arlo Williamsburg, as well as our evening venues, The Penny and Moxy Brooklyn Williamsburg, 
and lunches at Williamsburg's original boutique hotel - Wythe Hotel, you’ll be spoilt for choice. Experience these innovative venues as part of our curated discovery programme – the perfect opportunity to get a taste for Brooklyn’s growing meeting and events offering.
                </p>
               
                </div>
          </div>
        </div>
                          
                          
                          </div>

          </div>

              <div className="column rightSide afternoons" style={{ backgroundImage: `url(${Spotlight})` }}>
                          <div className='col-lg-12 ml-rg-auto py-5'>
                        
                        
                          </div>
              </div>
      </div>


      <div className="site-half section-title powder-bg" >
          <div className="container">
            <div className="row no-gutters">
           
            <div className="col-lg-12 mr-lg-auto py-5">
              <h2>Take a look below to see our accommodation and meetings venues. </h2>
             
            </div>

            </div>
          </div>
        </div>

      <div className="row splitrow celebrate" style={{backgroundColor:"#352E35"}}>
                      <div class="column leftSide celebrate" style={{ backgroundImage: slides[currentSlide].backgroundImage }}>
                      <div className='col-lg-12 ml-rg-auto py-5'>
                            
                          
                          
                          </div>

          </div>

          <div className="column rightSide celebrate">
        <div className='col-lg-12 ml-rg-auto py-5'>
          <div className="container carousel-content-container">
            <div className='col-lg-12 ml-lg-auto py-5' style={{ textAlign: 'center' }}>
              <h3 className='site-section-heading font-secondary' style={{ textDecoration: 'underline' }}>
                {slides[currentSlide].title}
              </h3>
              <div className="carousel-accommodation">
                <div className="carousel-accommodation-logo">
                  <a href={slides[currentSlide].website} target="_blank">
                    <img alt=" " src={slides[currentSlide].imageSrc} className="carousel-accommodationlogo" style={{ width: slides[currentSlide].width }}></img>
                  </a>
                </div>
                <br />
                <p>{slides[currentSlide].content}</p>
                <a href={slides[currentSlide].website} target="_blank">
                  <span className="btn btn-secondary transparent">Visit website</span>
                </a>
             
              </div>
                  {/* Slider buttons */}
      <div className="carouselbuttons">
        <ul>
          {slides.map((slide, index) => (
            <li key={index} className={index === currentSlide ? 'active' : ''} onClick={() => goToSlide(index)}></li>
          ))}
        </ul>
      </div>
            </div>
          </div>
        </div>
      </div>

     
     
              {/* <button onClick={prevSlide}>Previous</button>
      <button onClick={nextSlide}>Next</button> */}
      </div>

      <div className="site-half partnerSection">
          <div className="container">
            <div className="row no-gutters align-items-stretch">
              <div className="col-lg-12 ml-rg-auto py-5">
                <h3 className="site-section-heading font-secondary">
                  
                </h3>

                <Carousel images={headlineimages} links={headlinelinks} headings={headlineheadings} autoPlay />
                
              </div>
            </div>
          </div>
        </div>

    

      {/* <div className="site-half block" style={{ paddingBottom: "5rem" }}>
      <div id="scroll-container">
<div id="scroll-text">Meet with a wider range of MICE professionals at our Flagship Forum in Lisbon. </div>
</div>
  </div> */}

    

  {/* <div className='event-venue'>
                      <div className='container'>
                          <div className='row no-gutters align-items-stretch'>
                            

                          </div>
                          <div className='anchorScroll' id='itinerary'></div>
                      </div>
                  </div>  */}

        <div className="site-half sampleProgramme raven-bg">
        <h1>What to expect</h1>
          <div className="container">
            <div className="row no-gutters align-items-stretch">
             
           
            <div
              className="col-lg-4 ml-rg-auto py-5"
            >
               <div className="programmeItem">
                 <img
              alt={" "}
              src={Connect}
              className={"sampleThumbnails"}
            ></img>
             <h1 className="itemHeading">Connect</h1>
             <p className="itemCaption">Engage with a wide range of
American MICE buyers
and
international suppliers
across 40 relevant 1-2-1 meetings.  </p>
              </div>
              </div>

            
              <div
              className="col-lg-4 ml-rg-auto py-5"
            >
                <div className="programmeItem">
                 <img
              alt={" "}
              src={Experience}
              className={"sampleThumbnails"}
            ></img>
            <h1 className="itemHeading">Experience</h1>
             <p className="itemCaption">Shared experiences and destination discovery provide more opportunities to connect authentically and build long lasting business relationships. </p>
              </div>
              </div>

             
              <div
              className="col-lg-4 ml-rg-auto py-5"
            >
               <div className="programmeItem">
                 <img
              alt={" "}
              src={Enjoy}
              className={"sampleThumbnails"}
            ></img>
            <h1 className="itemHeading">Enjoy</h1>
             <p className="itemCaption">Make memories with new business contacts at our m&i After Dark evening experiences hosted at top MICE venues.  </p>
              </div>
              </div>

            </div>
          </div>
        </div>
        <div className="packages-section">
          <div className="container">
            <div className="row no-gutters align-items-stretch">
              <div className="col-lg-12 ml-lg-auto py-5 ">

                <h2 className="site-section-heading font-secondary">
                  <span style={{fontSize:"20px"}}>
                    What's in it for you?
                  </span>
                </h2>

                <div className="tabs">
      <div
        className={`tab ${activeTab === 'suppliers' ? 'active' : ''}`}
        onClick={() => toggleAccordion('suppliers')}
      >
        Suppliers
      </div>
      <div className="separator">|</div>
      <div
        className={`tab ${activeTab === 'buyers' ? 'active' : ''}`}
        onClick={() => toggleAccordion('buyers')}
      >
        Buyers
      </div>
    </div>
    <div className={`accordion ${activeTab === 'suppliers' ? 'open' : ''}`}>
      {/* Suppliers content */}
      <div className="content">

      <ul className='packages'>

       

 

 




                                                      <li>
                                                      Grow your buyer network (90% USA and 10% Americas) across 3 days of immersive networking and 40 guaranteed 1-2-1 meetings
                                                      </li>
                                                      <li>
                                                      Cutting edge AI-matched meetings that, on average, provides suppliers with x10 ROI and lasting, relevant business connections 
                                                      </li>
                                                      <li>
                                                      Networking activities facilitating maximum buyer facetime  
                                                      </li>
                                                      <li>
                                                      Outstanding networking dinners to foster connection in an informal setting 
                                                      </li>
                                                      <li>
                                                      Friendly Account Manager to guide you from start to finish 
                                                      </li>
                                                     
                                                      {/* <li>Exceptional dinners and glamorous nightlife experiences  </li>
                                                    
                                                     <li>Friendly Account Manager to guide you from start to finish </li>
                                                      <li>
                                                          Make your money go
                                                          further with our{' '}
                                                          <a href='/pricing'>
                                                              early payment
                                                              discount
                                                          </a>
                                                      </li>

                                                    
                                                      <li>
                                                          Find out how we're{' '}
                                                          <a href='https://www.mi-forums.com/covid-safe-events'>
                                                              keeping you safe
                                                          </a>{' '}
                                                          at this event
                                                      </li>
                                                      <li>
                                                          New to m&i?
                                                          Wondering what to
                                                          expect at our events
                                                          ? See{' '}
                                                          <a href='https://mi-forums.com/supplier-experience'>
                                                              how it works
                                                          </a>
                                                      </li> */}
                                                  </ul>

        
      </div>
    </div>
    <div className={`accordion ${activeTab === 'buyers' ? 'open' : ''}`}>
      {/* Buyers content */}
      <div className="content">

     
      <ul className='packages'>
                                                      <li>
                                                      Grow your network of high-quality international suppliers (50% USA, 30% Europe & 20% Americas) through 1-2-1 meetings 
                                                      </li>
                                                      <li>Cutting edge AI-matched meetings providing you with a guaranteed return on your time and lasting, relevant business connections  </li>
                                                      <li>Bespoke buyer package, including accommodation and transfers, as well as airfare credit  </li>
                                                      <li>
                                                      Breakfast, lunch and networking dinners provided 
                                                      </li>

                                                      <li>
                                                      Experience the MICE highlights of New York with our tailor-made programme 
                                                      </li>
                                                      <li>
                                                      Networking activities — experience Brooklyn through the eyes of your clients 
                                                      </li>
                                                      {/* <li>
                                                      Glamorous parties and nightlife experiences 
                                                      </li>
                                                     

                                                    
                                                      <li>
                                                          Find out how we're{' '}
                                                          <a href='https://www.mi-forums.com/covid-safe-events'>
                                                              keeping you safe
                                                          </a>{' '}
                                                          at this event
                                                      </li>
                                                      <li>
                                                          New to m&i?
                                                          Wondering what to
                                                          expect at our events
                                                          ? See{' '}
                                                          <a href='https://mi-forums.com/supplier-experience'>
                                                              how it works
                                                          </a>
                                                      </li> */}
                                                  </ul>
      </div>
    </div>

    
            
               
              </div>
            
            </div>
          </div>
        </div>

        <div className="site-half brooklyn partnerSection">
          <div className="container">
            <div className="row no-gutters align-items-stretch">
              <div className="col-lg-12 ml-rg-auto py-5">
                <h3 className="site-section-heading font-secondary">
                  
                </h3>

                <Carousel images={eventimages} links={eventlinks} headings={eventheadings} autoPlay />
                
              </div>
            </div>
          </div>
        </div>

        <div
          className="cta-banner"
          style={{ backgroundImage: `url(${CTA})` }}
        >
          <div className="container">
            <div className="row no-gutters align-items-stretch">
              <div className="col-lg-12 ml-rg-auto py-5 laptop">
                <div className="cta-content">
                <div
                  className="footer-section-heading "
               
                >
                <h3>m&i Brooklyn 25</h3>
                 <h1>Elevate your MICE business in 2025.   </h1>
                 <h3 className="caption">Save your space today. </h3>
                </div>
                  <a href="/signup">
                    <span className=" btn btn-primary">
                    See if you qualify
                    </span>
                  </a>
                  <br/><br/><br/>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      


      
      </div>
    </div>
  </>
);
};

export default Brooklyn;
