

import React from "react"
import "../assets/scss/style.scss"
import { Helmet } from "react-helmet"

const CostaMujeresAccomodationBooking = () => {
  return (
    <>
      <div class="form-container">
       <div id="ftEmbedForm-ft549b2c63e87a4190b08f0a3bb33d38c7"></div>
      </div>


      <Helmet>
    <script src="https://efress.formtitan.com/publish/v1/embed?project=ft1d4ffaf618e14463bed99783ad2cf6f2&width=100%&height=700&guid=ft549b2c63e87a4190b08f0a3bb33d38c7" type="text/javascript"/>
</Helmet>
    </>
  )
}

export default CostaMujeresAccomodationBooking


