import React from "react"
import "../assets/scss/style.scss"

import { Helmet } from "react-helmet"

const PreEventDetails = () => {
  
  return (
    <>
    
    <div class="form-container">
    <div id="ftEmbedForm-ftd26976baa55a4545ab38c3e16e890d56" ></div>
      </div>


      <Helmet>
    <script src="https://efress.formtitan.com/publish/v1/embed?project=ft4aa39f0c89ab45f3be33f391545efa51&width=100%&height=700&guid=ftd26976baa55a4545ab38c3e16e890d56" type="text/javascript"/>

</Helmet>
    </>
  )
}

export default PreEventDetails

