import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import Carousel from "../../components/elements/Carousel"
import Sticky from "react-sticky-el";
import "../../assets/scss/style.scss";
import EpicSana from "../../assets/images/events/partner_logos/EpicSana.jpg"
import Connect from "../../assets/images/events/2025/gothenburg/business.png";
import Experience from "../../assets/images/events/2025/gothenburg/meetings.png";
import Enjoy from "../../assets/images/events/2025/gothenburg/nights.png";
import CTA from "../../assets/images/events/2025/gothenburg/cta.png";
import Spotlight from "../../assets/images/events/2025/gothenburg/spotlight.png";
import Accommodation from "../../assets/images/events/2025/gothenburg/accommodation.png";
import PortugalTravel from "../../assets/images/events/partner_logos/Portugal_Travel.jpg";
import Vega from "../../assets/images/events/partner_logos/vega_logo.png";
import Fairmont from "../../assets/images/events/2025/gothenburg/scandic.png";
import FairmontB from  "../../assets/images/events/2025/lakelouise/fairmont-b.png";
import BLL from  "../../assets/images/events/2025/lakelouise/BLL.png";
import Header from "../../assets/images/events/2025/gothenburg/header.png";
import RareIndigo from "../../assets/images/events/partner_logos/rareindigo.jpg"
import ScandicWhite from "../../assets/images/events/2025/gothenburg/scandic-white.png";
import Scandic from "../../assets/images/events/2025/gothenburg/scandic.png";
import Gcb from "../../assets/images/events/2025/gothenburg/gcb.png";
import Strawberry from "../../assets/images/events/2025/gothenburg/strawberry.png"
import Limited from "../../assets/images/events/2025/gothenburg/limited.png";
import DavosService from "../../assets/images/events/2025/gothenburg/davosservice.png";
import Davos from "./Davos";

const Gothenburg = () => {

  
  const slides = [
    {
      backgroundImage: `url(${Accommodation})`,
      title: 'Accommodation and meetings venues',
      imageSrc: ScandicWhite,
      content: 'Situated just 700 metres from Gothenburg Central Station, Scandic Göteborg Central features 451 rooms and a generous event space incorporating 18 meeting rooms and a 1000-person conference hall. Whatever the healthcare event, our host venue has the MICE facilities to fulfil every event brief.  ',
      website: 'https://www.scandichotels.se/hotell/sverige/goteborg/scandic-goteborg-central',
      width: '200px', 
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  
  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % slides.length);
  };
  
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((currentSlide + 1) % slides.length);
    }, 5000);
  
    return () => clearInterval(intervalId);
  }, [currentSlide, slides.length]);
  
  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + slides.length) % slides.length);
  };
  
  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  
    const [activeTab, setActiveTab] = useState('suppliers'); // Set the initial active tab to 'suppliers'

    const toggleAccordion = (tabName) => {
      setActiveTab(activeTab === tabName ? null : tabName);
    };

    const videoEl = useRef(null);

const attemptPlay = () => {
  videoEl &&
    videoEl.current &&
    videoEl.current.play().catch(error => {
      console.error("Error attempting to play", error);
    });
};
const handleScroll = () => {
      const sectionElement = document.getElementById('scrollto');
      // If you want to scroll to a specific section by its ID

    // const percentageToScroll = 0.5;
    // const offset = window.innerHeight * percentageToScroll;
    // If you want to scroll a certain percentage down the page

    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
   
  const textRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    
      

  useEffect(() => {

    
attemptPlay();

   

   
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);

     
    };

    
  }, []);

  const images = [
    
    [
     Scandic
    ], [Gcb, Limited, Strawberry]
  ];
 

  
  const links = [
   
    ['https://www.scandichotels.se/hotell/sverige/goteborg/scandic-goteborg-central'],[ 'www.goteborgco.se', 'www.limitedofsweden.se', 'www.strawberryhotels.com']
  ];
  const headings = ['Headline Partner', 'Event Partners'];


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>m&i Healthcare Gothenburg | UK & EU buyers</title>
        <meta
          name="description"
          content="The ultimate B2B experience for the luxury incentive travel industry  "
        />
        <meta
          property="og:url"
          content={
            "https://www.mi-forums.com/images/ographs/OG_image_Europe_Autumn_2022.png"
          }
        />
        <meta
          property="og:image"
          content={
            "https://www.mi-forums.com/images/ographs/OG_image_Europe_Autumn_2022.png"
          }
        />
      </Helmet>
      <div className="site-wrap">
        <div className="event-page twentyfour lakelouise powder-bg">
          <div
            className="sprint-hero-slider" style={{
             // backgroundImage: `url(${Header})`,
          }}>

<div class="video-container">
    <iframe class="hero-background-video" 
            src="https://www.youtube.com/embed/YoaYl_Ka58g?rel=0&amp;autoplay=1&amp;mute=1&amp;playsinline=1&amp;controls=0&amp;showinfo=0&amp;loop=1&amp;playlist=YoaYl_Ka58g" 
            frameborder="0" 
            allowfullscreen="allowfullscreen">
    </iframe>
</div>

       

<div className="row no-gutters align-items-stretch">
                  <div className="header-section-heading">
                  {/* <h3>Healthcare</h3> */}
                   <h1>m&i Healthcare Gothenburg </h1>
                   <h3>UK & EU buyers    </h3>
                   <h4>29 Jun - 2 Jul | Sweden </h4>
                   <div className="scroll-arrow" onClick={handleScroll} style={{top:"35rem"}}>
     <svg width="70" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" fill="#E0C7B2">
<g><path d="M500,10"/><path d="M500,990L108,598h784L500,990z"/></g>
</svg>
    </div>
                 
                  </div>
              </div>


         
          </div>

          <Sticky>
            <div className="event-under-banner twentyfour raven-bg" id="scrollto">
              <div className="container">
                <div className="row no-gutters align-items-stretch">
                  <div className=" ml-rg-auto event-sticky-text">
                    <h3>Love the sound of this event?</h3>
                  </div>
                  <div className="ml-rg-auto event-buttons flex-wrap ">
                    <a href="/tell-me-more">
                      <span className=" btn btn-secondary transparent">
                        Tell me more
                      </span>
                    </a>
                    <a href="/signup">
                      <span className=" btn btn-secondary grey">
                      Register now
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Sticky>
       
          <div className="site-half eventInformationSection powder-bg" >
            <div className="container">
              <div className="row no-gutters center-content">
             
              <div className="col-lg-10 mr-lg-auto py-5" style={{paddingRight:'20px'}}>
                <h2>Transform your <br/> healthcare events business </h2>
                <br/>
                <p>
                Join us at an event designed specifically for healthcare-certified venues and healthcare event planners. Meet and connect with interested professionals through 35 guaranteed meetings and expand your industry knowledge through topical educational sessions.  
                </p>
              </div>

              </div>
            </div>
            <div className="anchorScroll" id="numbers"></div>
          </div>

          <div className="numbers-section eventNumbers powder-bg">
            <div className="container">
              <div className="row no-gutters align-items-stretch">
                <div
                  className="col-lg-12 ml-rg-auto py-4 laptop"
                  style={{ color: "#fff" }}
                >
                  <div className="container">
                    <div className="inNumbers">
                    
                      <ul>
                        <li>
                          <div className="numbers-caption ">
                            <h1 className="eventNumbers raven">3</h1>
                            <p>Days</p>
                          </div>
                        </li>
                        <li>
                          <div className="numbers-caption">
                            <h1 className="eventNumbers raven">35</h1>
                            <p>1-2-1 Meetings</p>
                          </div>
                        </li>
                        <li>
                          {" "}
                          <div className="numbers-caption">
                            <h1 className="eventNumbers raven">120</h1>
                            <p>Healthcare-certified suppliers </p>
                          </div>
                        </li>
                        <li>
                          <div className="numbers-caption">
                            <h1 className="eventNumbers raven">130</h1>
                            <p>Healthcare buyers from Europe & UK </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="anchorScroll" id="spotlight"></div>
            </div>
          </div>


         
          <div className="row splitrow explore" style={{backgroundColor:"#ECDDD1"}}>
                        <div class="column leftSide afternoons"  >
                        <div className='col-lg-12 ml-rg-auto py-5'>
                              
                        <div className="container">
            <div className="row no-gutters align-items-stretch">
              <div
                className="col-lg-7 ml-rg-auto "
              >
               
                <h1 className="site-section-heading font-secondary">
                Spotlight on Gothenburg
                </h1>
               
            
                <p
                    
                    className="mobilePadding-2"
                  >
      No stranger to hosting large-scale conferences such as the World Economic Forum, Sweden’s second-largest city is home to impressive infrastructure and a wide range of MICE-ready hotels. 
                    <br />
                    <br />
                    In addition to producing ground-breaking life sciences research, Gothenburg leads the way to sustainable change in the wider MICE sector and has been named the World's Most Sustainable Destination by the Global Destination Sustainability Index for the seventh year in a row.  
                  </p>
                 
                  </div>
            </div>
          </div>
                            
                            
                            </div>

            </div>

                <div className="column rightSide afternoons" style={{ backgroundImage: `url(${Spotlight})` }}>
                            <div className='col-lg-12 ml-rg-auto py-5'>
                          
                          
                            </div>
                </div>
        </div>

        <div className="site-half section-title powder-bg" >
          <div className="container">
            <div className="row no-gutters">
           
            <div className="col-lg-12 mr-lg-auto py-5">
              <h2>Take a look below to see our accommodation and meetings venues. </h2>
             
            </div>

            </div>
          </div>
        </div>

      <div className="row splitrow celebrate" style={{backgroundColor:"#352E35"}}>
                      <div class="column leftSide celebrate" style={{ backgroundImage: slides[currentSlide].backgroundImage }}>
                      <div className='col-lg-12 ml-rg-auto py-5'>
                            
                          
                          
                          </div>

          </div>

          <div className="column rightSide celebrate">
        <div className='col-lg-12 ml-rg-auto py-5'>
          <div className="container carousel-content-container">
            <div className='col-lg-12 ml-lg-auto py-5' style={{ textAlign: 'center' }}>
              <h3 className='site-section-heading font-secondary' style={{ textDecoration: 'underline' }}>
                {slides[currentSlide].title}
              </h3>
              <div className="carousel-accommodation">
                <div className="carousel-accommodation-logo">
                  <a href={slides[currentSlide].website} target="_blank">
                    <img alt=" " src={slides[currentSlide].imageSrc} className="carousel-accommodationlogo" style={{ width: slides[currentSlide].width }}></img>
                  </a>
                </div>
                <br />
                <p>{slides[currentSlide].content}</p>
                <a href={slides[currentSlide].website} target="_blank">
                  <span className="btn btn-secondary transparent">Visit website</span>
                </a>
             
              </div>
                 
            </div>
          </div>
        </div>
      </div>

     
     
              {/* <button onClick={prevSlide}>Previous</button>
      <button onClick={nextSlide}>Next</button> */}
      </div>


        <div className="site-half partnerSection">
            <div className="container">
              <div className="row no-gutters align-items-stretch">
                <div className="col-lg-12 ml-rg-auto py-5">
                  <h3 className="site-section-heading font-secondary">
                    
                  </h3>

                  <Carousel images={images} links={links} headings={headings} autoPlay />
                  
                </div>
              </div>
            </div>
          </div>

      

        {/* <div className="site-half block" style={{ paddingBottom: "5rem" }}>
        <div id="scroll-container">
  <div id="scroll-text">Meet with a wider range of MICE professionals at our Flagship Forum in Lisbon. </div>
</div>
    </div> */}

      

    {/* <div className='event-venue'>
                        <div className='container'>
                            <div className='row no-gutters align-items-stretch'>
                              

                            </div>
                            <div className='anchorScroll' id='itinerary'></div>
                        </div>
                    </div>  */}

          <div className="site-half sampleProgramme raven-bg">
          <h1>What to expect</h1>
            <div className="container">
              <div className="row no-gutters align-items-stretch">
               
             
              <div
                className="col-lg-4 ml-rg-auto py-5"
              >
                 <div className="programmeItem">
                   <img
                alt={" "}
                src={Connect}
                className={"sampleThumbnails"}
              ></img>
               <h1 className="itemHeading">Connect</h1>
               <p className="itemCaption">Grow your network across 35 meetings with healthcare events professionals.   </p>
                </div>
                </div>

              
                <div
                className="col-lg-4 ml-rg-auto py-5"
              >
                  <div className="programmeItem">
                   <img
                alt={" "}
                src={Experience}
                className={"sampleThumbnails"}
              ></img>
              <h1 className="itemHeading">Experience</h1>
               <p className="itemCaption">Explore Gothenburg’s MICE infrastructure first-hand through site visits and networking activities.  </p>
                </div>
                </div>

               
                <div
                className="col-lg-4 ml-rg-auto py-5"
              >
                 <div className="programmeItem">
                   <img
                alt={" "}
                src={Enjoy}
                className={"sampleThumbnails"}
              ></img>
              <h1 className="itemHeading">Learn</h1>
               <p className="itemCaption">Educate yourself on key topics affecting the healthcare events ecosystem through engaging content.   </p>
                </div>
                </div>

              </div>
            </div>
          </div>
          <div className="packages-section">
            <div className="container">
              <div className="row no-gutters align-items-stretch">
                <div className="col-lg-12 ml-lg-auto py-5 ">

                  <h2 className="site-section-heading font-secondary">
                    <span style={{fontSize:"20px"}}>
                      What's in it for you?
                    </span>
                  </h2>

                  <div className="tabs">
        <div
          className={`tab ${activeTab === 'suppliers' ? 'active' : ''}`}
          onClick={() => toggleAccordion('suppliers')}
        >
          Suppliers
        </div>
        <div className="separator">|</div>
        <div
          className={`tab ${activeTab === 'buyers' ? 'active' : ''}`}
          onClick={() => toggleAccordion('buyers')}
        >
          Buyers
        </div>
      </div>
      <div className={`accordion ${activeTab === 'suppliers' ? 'open' : ''}`}>
        {/* Suppliers content */}
        <div className="content">

        <ul className='packages'>
                                                        <li>
                                                        Grow your healthcare buyer network across three days of 1-2-1 meetings and networking opportunities 
                                                        </li>
                                                        <li>
                                                        Benefit from a meetings schedule that’s built around your preferences, bringing you relevant business connections and lasting ROI  
                                                        </li>
                                                        <li>
                                                        Maximise your facetime with buyers through networking activities  
                                                        </li>
                                                        <li>
                                                        Build on your connections during networking dinners in healthcare-certified venues 
                                                        </li>
                                                        <li>
                                                        Get venue accreditation and specialist delegate training via our event partner, Healthcare Venues 
                                                        </li>
                                                       
                                                        <li>Expand your knowledge through educational sessions hosted by key industry speakers  </li>
                                                      
                                                       <li>Be guided from start to finish by a dedicated Account Manager  </li>
                                                       
</ul>
          
        </div>
      </div>
      <div className={`accordion ${activeTab === 'buyers' ? 'open' : ''}`}>
        {/* Buyers content */}
        <div className="content">

       
        <ul className='packages'>
                                                        <li>
                                                        Grow your network of healthcare-certified suppliers across three days of 1-2-1 meetings and networking opportunities 
                                                        </li>
                                                        <li>Benefit from a meetings schedule that’s built around your preferences, bringing you relevant business connections and guaranteed return on time 
                                                        </li>

                                                        <li>
                                                        Get a bespoke buyer package that includes accommodation, transfers, F&B, and airfare credit 
                                                        </li>
                                                        <li>
                                                        Discover the MICE highlights of Gothenburg through site visits and networking activities 
                                                        </li>
                                                        <li>
                                                        Expand your knowledge through educational sessions hosted by key industry speakers 
                                                        </li>
                                                       

                                                    </ul>
        </div>
      </div>

      
              
                 
                </div>
              
              </div>
            </div>
          </div>

          <div
            className="cta-banner"
            style={{ backgroundImage: `url(${CTA})` }}
          >
            <div className="container">
              <div className="row no-gutters align-items-stretch">
                <div className="col-lg-12 ml-rg-auto py-5 laptop">
                  <div className="cta-content">
                  <div
                    className="footer-section-heading "
                 
                  >
                  <h3>m&i Healthcare Gothenburg</h3>
                   <h1>Want to connect with top healthcare professionals?  </h1>
                   {/* <h3 className="caption">Save your space today.  </h3> */}
                  </div>
                    <a href="/signup">
                      <span className=" btn btn-primary">
                    Register your inteest
                      </span>
                    </a>
                    <br/><br/><br/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        


        
        </div>
      </div>
    </>
  );
};

export default Gothenburg;
