import React from "react"
import "../assets/scss/style.scss"

const SelfBooker = () => {
  return (
    <>
      <div class="form-container">
        <iframe
          onload="window.parent.scrollTo(0,0)"
          id="ft1673889682419-213-25"
          title="SupplierProfile"
          allowtransparency="true"
          src="https://efress.formtitan.com/mi-self-booker-details"
          frameborder="0"
          scrollable="yes"
        ></iframe>
      </div>
    </>
  )
}

export default SelfBooker
