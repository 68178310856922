import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import enableInlineVideo from 'iphone-inline-video';
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import Sticky from "react-sticky-el";
import "../../assets/scss/style.scss";
import Connect from "../../assets/images/events/2025/brooklyn/Connect.png";
import Experience from "../../assets/images/events/2025/brooklyn/Experience.png";
import Enjoy from "../../assets/images/events/2025/brooklyn/Enjoy.png";
import Adebar from "../../assets/images/events/partner_logos/adebar.png";
import Amex from "../../assets/images/events/partner_logos/amex.svg";
import Graydawes from "../../assets/images/events/partner_logos/graydawes.png";
import Marriott from "../../assets/images/events/partner_logos/marriott.webp";
import PrestigiousVenues from "../../assets/images/events/partner_logos/prestigiousvenues.jpg"
import useModal from '../../components/elements/useModal';
import MandalaShape from "../../assets/images/events/2025/fest/MandalaShape.svg";
import CutShape from "../../assets/images/events/2025/fest/CutShape.svg";
import CutShape2 from "../../assets/images/events/2025/fest/CutShape2.svg";
import CTA from "../../assets/images/events/2025/fest/CTA.png";
import Header from "../../assets/images/events/2025/fest/header.png";
import Festlogo from "../../assets/images/events/2025/fest/miglobal.png";
import Watchrecap from "../../assets/images/events/2025/fest/watchrecap.png";
import Image1 from "../../assets/images/events/2025/fest/Image1.png";
import Image2 from "../../assets/images/events/2025/fest/Image2.png";
import Image3 from "../../assets/images/events/2025/fest/Image3.png";
import Image4 from "../../assets/images/events/2025/fest/Image4.png";
import Image5 from "../../assets/images/events/2025/fest/Image5.png";
import Image6 from "../../assets/images/events/2025/fest/Image6.png";
import Image7 from "../../assets/images/events/2025/fest/Image7.png";
import Mornings from "../../assets/images/events/2025/fest/Mornings.png";
import Afternoons from "../../assets/images/events/2025/fest/Afternoons.png";
import Nights from "../../assets/images/events/2025/fest/Nights.png";
import Movenpick from "../../assets/images/events/2025/fest/Movenpick.png";
import Movenpicklogo from "../../assets/images/events/2025/fest/Movenpicklogo.png";
import Whattoexpect from "../../assets/images/events/2025/fest/Whattoexpect.png";
import Kongres from "../../assets/images/events/2025/fest/Kongres.png"
import Travel from "../../assets/images/events/2025/fest/Travel.png"
import CMW from "../../assets/images/events/2025/fest/CMW.png"
import MorningsVector from "../../assets/images/events/2025/fest/Mornings.svg";
import AfternoonVector from "../../assets/images/events/2025/fest/Afternoons.svg"
import NightsVector from "../../assets/images/events/2025/fest/Nights.svg"
import ArloLogoBlack from "../../assets/images/events/partner_logos/arlo-logo-black.png"
import MoxyLogoBlack from "../../assets/images/events/partner_logos/moxy-logo-black.png"
import WytheLogoBlack from "../../assets/images/events/partner_logos/wythe-logo-black.png"
import HoxtonLogoBlack from "../../assets/images/events/partner_logos/hoxton-logo-black.png"
import PennyLogoBlack from "../../assets/images/events/partner_logos/penny-logo-black.png"
import NYC from "../../assets/images/events/partner_logos/newyorkcity.svg"
import Pattern1 from "../../assets/images/events/2025/fest/Pattern1.svg";

const Carousel = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(1); // Start at 1 to include the initial offset
  const length = items.length;
  const intervalRef = useRef(null);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    startAutoScroll();
    return () => stopAutoScroll();
  }, []);

  const startAutoScroll = () => {
    intervalRef.current = setInterval(() => {
      nextSlide();
    }, 999999); // Change slide every 3 seconds
  };

  const stopAutoScroll = () => {
    clearInterval(intervalRef.current);
  };

  const nextSlide = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const prevSlide = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleTransitionEnd = () => {
    setIsTransitioning(false);
    if (currentIndex >= length + 1) {
      setCurrentIndex(1);
    } else if (currentIndex <= 0) {
      setCurrentIndex(length);
    }
  };

  const getTranslateX = () => {
    const width = window.innerWidth;
    if (width < 768) {
      // On mobile, start at -6% and move -111% each slide
      return -6 + (currentIndex - 1) * -111;
    } else {
      // On desktop, start at -45% and move -65% each slide
      return -45 + (currentIndex - 1) * -65;
    }
  };

  return (
    <div className="testimonialfest">
      <div 
        className="testimonial-inner" 
        style={{ 
          transform: `translateX(${getTranslateX()}%)`,
          transition: isTransitioning ? 'transform 0.5s ease' : 'none',
        }}
        onTransitionEnd={handleTransitionEnd}
      >
        <div className="testimonial-item">{items[length - 1]}</div> {/* Clone of the last item */}
        {items.map((item, index) => (
          <div className="testimonial-item" key={index}>
            {item}
          </div>
        ))}
        <div className="testimonial-item">{items[0]}</div> {/* Clone of the first item */}
      </div>
      <div className="testimonial-indicators">
        {items.map((_, index) => (
          <span
            key={index}
            className={`indicator ${index + 1 === currentIndex || (currentIndex === length + 1 && index === 0) ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index + 1)}
          ></span>
        ))}
      </div>
    </div>
  );
};





const Fest25 = () => {


  const { isShowing, toggle } = useModal();
  const [modal, setModal] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);
  const spinner = () => {
    setVideoLoading(!videoLoading);
  };

  const openModal = () => {
    setModal(!modal);
  };

  const cards = [

    <div className="card"><div className="testimonial-quotation">  <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill="#F77441" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 379.51" 
    style={{width:"26px",display:"block", paddingBottom:"33px"}}>
<path d="M212.27 33.98C131.02 56.52 78.14 103.65 64.99 185.67c-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160.01 60.56 27.12 149.48-159.79 175.36-215.11 92.8-12.87-19.19-21.39-41.59-24.46-66.19C-11.35 159.99 43.48 64.7 139.8 19.94c17.82-8.28 36.6-14.76 56.81-19.51 10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78zm267.78 0c-81.25 22.54-134.14 69.67-147.28 151.69-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160 60.56 27.13 149.48-159.78 175.36-215.1 92.8-12.87-19.19-21.39-41.59-24.46-66.19C256.43 159.99 311.25 64.7 407.58 19.94 425.4 11.66 444.17 5.18 464.39.43c10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78z"/></svg></div>
<div className="testimonial-logos">
<img style={{width: "110px"}} src={PrestigiousVenues}></img></div>
<div className="testimonial-text">

m&i Fest Marrakech retains its buyer/supplier personalisation and destination-led experiences in this larger format event, which shows us that m&i is developing for the future, not resting on laurels, which is good for the whole industry</div>
    
    <br/><br/><strong>Habib Amir, <br/>Prestigious Venues 
 </strong></div>,

<div className="card"><div className="testimonial-quotation">  <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill="#F77441" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 379.51" 
style={{width:"26px",display:"block", paddingBottom:"33px"}}>
<path d="M212.27 33.98C131.02 56.52 78.14 103.65 64.99 185.67c-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160.01 60.56 27.12 149.48-159.79 175.36-215.11 92.8-12.87-19.19-21.39-41.59-24.46-66.19C-11.35 159.99 43.48 64.7 139.8 19.94c17.82-8.28 36.6-14.76 56.81-19.51 10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78zm267.78 0c-81.25 22.54-134.14 69.67-147.28 151.69-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160 60.56 27.13 149.48-159.78 175.36-215.1 92.8-12.87-19.19-21.39-41.59-24.46-66.19C256.43 159.99 311.25 64.7 407.58 19.94 425.4 11.66 444.17 5.18 464.39.43c10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78z"/></svg></div>

<div className="testimonial-logos">
<img style={{width: "70px"}} src={Graydawes}></img></div>
<div className="testimonial-text">
This was the perfect mix of making new connections and catching up with industry colleagues. The staff at m&i were brilliant throughout and did a great job. We always worry about whether our days out of the office will be worth it - but this 100% was. Great work team! Looking forward to the next!</div>

<br/><br/><strong>Danielle Grubb, <br/>Gray Dawes Travel 
</strong></div>,




    <div className="card"><div className="testimonial-quotation">  <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill="#F77441" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 379.51" 
    style={{width:"26px",display:"block", paddingBottom:"33px"}}>
<path d="M212.27 33.98C131.02 56.52 78.14 103.65 64.99 185.67c-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160.01 60.56 27.12 149.48-159.79 175.36-215.11 92.8-12.87-19.19-21.39-41.59-24.46-66.19C-11.35 159.99 43.48 64.7 139.8 19.94c17.82-8.28 36.6-14.76 56.81-19.51 10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78zm267.78 0c-81.25 22.54-134.14 69.67-147.28 151.69-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160 60.56 27.13 149.48-159.78 175.36-215.1 92.8-12.87-19.19-21.39-41.59-24.46-66.19C256.43 159.99 311.25 64.7 407.58 19.94 425.4 11.66 444.17 5.18 464.39.43c10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78z"/></svg></div>

<div className="testimonial-logos">
<img style={{width: "110px"}} src={Adebar}></img></div>

<div className="testimonial-text">
m&i Fest 2024 was my first experience with this event format. Although meetings are also central at m&i, compared to other large events like IBTM or IMEX, the connections I made were definitely deepened thanks to the fabulously grand evening events. Consequently, the exchange is more efficient and sustainable. It was simply magnificent.</div>
    
    <br/><br/><strong>Tom Kothe, <br/>adebar GmbH</strong></div>,



<div className="card"><div className="testimonial-quotation">  <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill="#F77441" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 379.51" 
style={{width:"26px",display:"block", paddingBottom:"33px"}}>
<path d="M212.27 33.98C131.02 56.52 78.14 103.65 64.99 185.67c-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160.01 60.56 27.12 149.48-159.79 175.36-215.11 92.8-12.87-19.19-21.39-41.59-24.46-66.19C-11.35 159.99 43.48 64.7 139.8 19.94c17.82-8.28 36.6-14.76 56.81-19.51 10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78zm267.78 0c-81.25 22.54-134.14 69.67-147.28 151.69-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160 60.56 27.13 149.48-159.78 175.36-215.1 92.8-12.87-19.19-21.39-41.59-24.46-66.19C256.43 159.99 311.25 64.7 407.58 19.94 425.4 11.66 444.17 5.18 464.39.43c10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78z"/></svg></div>
<div className="testimonial-logos">
<img style={{width: "110px"}} src={Amex}></img></div>

<div className="testimonial-text">As a seasoned professional in the travel industry, attending the recent event was an enriching experience. The many networking opportunities, and discussions with industry leaders have provided invaluable insights and strategies to enhance our business practices. I'm excited to implement these learnings and continue driving success in our ever-evolving industry.</div>

<br/><br/><strong>Maxine Frisk,<br/> American Express Global Business Travel Finland</strong></div>,


<div className="card"><div className="testimonial-quotation">  <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill="#F77441" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 379.51" 
style={{width:"26px",display:"block", paddingBottom:"33px"}}>
<path d="M212.27 33.98C131.02 56.52 78.14 103.65 64.99 185.67c-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160.01 60.56 27.12 149.48-159.79 175.36-215.11 92.8-12.87-19.19-21.39-41.59-24.46-66.19C-11.35 159.99 43.48 64.7 139.8 19.94c17.82-8.28 36.6-14.76 56.81-19.51 10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78zm267.78 0c-81.25 22.54-134.14 69.67-147.28 151.69-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160 60.56 27.13 149.48-159.78 175.36-215.1 92.8-12.87-19.19-21.39-41.59-24.46-66.19C256.43 159.99 311.25 64.7 407.58 19.94 425.4 11.66 444.17 5.18 464.39.43c10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78z"/></svg></div>
<div className="testimonial-logos">
<img style={{width: "70px"}} src={Marriott}></img></div>

<div className="testimonial-text"> m&i Fest is a unique event where buyers and suppliers are brought together in a unique way. It felt like the sky was the limit. In one word, WOW. </div>

<br/><br/><strong>Matthias Dujardijn,<br/> Marriott Ghent & Residence Inn Ghent </strong></div>,


   
  ];


  const [currentSlide, setCurrentSlide] = useState(0);
  const [activeTab, setActiveTab] = useState('suppliers'); // Set the initial active tab to 'suppliers'

  const toggleAccordion = (tabName) => {
    setActiveTab(activeTab === tabName ? null : tabName);
  };

  const videoEl = useRef(null);

const attemptPlay = () => {
videoEl &&
  videoEl.current &&
  videoEl.current.play().catch(error => {
    console.error("Error attempting to play", error);
  });
};
const handleScroll = () => {
    const sectionElement = document.getElementById('scrollto');
    // If you want to scroll to a specific section by its ID

  // const percentageToScroll = 0.5;
  // const offset = window.innerHeight * percentageToScroll;
  // If you want to scroll a certain percentage down the page

  if (sectionElement) {
    sectionElement.scrollIntoView({ behavior: 'smooth' });
  }
};
 
const textRef = useRef(null);
const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  
    

useEffect(() => {

  
attemptPlay();

 

 
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };
  window.addEventListener("resize", handleWindowResize);
  return () => {
    window.removeEventListener("resize", handleWindowResize);

   
  };

  
}, []);

const gridData = [
  { id: 1, value: "7-10 RFPs", description: "Average number of enquiries given by buyers during m&i Fest" },
  { id: 2, value: "99/100", description: "Overall event score from our buyers" },
  { id: 3, value: "#1", description: "Our top buyer event" },
  { id: 4, value: "72 NPS", description: "m&i Fest Net promoter score (NPS) was at an all time high\n\nIndustry benchmark: 5" },
  { id: 5, value: "55k", description: "Industry connections created during the event" },
  { id: 6, value: "84%", description: "of exhibitors said m&i Fest offered great potential ROI" }
];


const items = [
  {
    id: 1,
    text: "m&l Fest is the most effective way to base of MICE clients, who, sooner business for your company.",
    author: "P Hotels"
  },
  {
    id: 2,
    text: "m&l Fest is a unique event where buyers and suppliers are brought together in a unique way. It felt like the sky was the limit. In one word, WOW.",
    author: "Matthias Dujardin, Marriott Ghent & Residence Inn Ghent"
  },
  {
    id: 3,
    text: "m&l Fest was an exceptional opportunity to establish great new connections with industry leaders and buyers.",
    author: "Katja Remus, Meliá Hotels International"
  },
  {
    id: 4,
    text: "m&l Fest is the most effective way to base of MICE clients, who, sooner business for your company.",
    author: "P Hotels"
  },
  {
    id: 5,
    text: "m&l Fest is a unique event where buyers and suppliers are brought together in a unique way. It felt like the sky was the limit. In one word, WOW.",
    author: "Matthias Dujardin, Marriott Ghent & Residence Inn Ghent"
  }
];



const [activeIndex, setActiveIndex] = useState(0);

// Function to move to the next card
const next = () => {
  setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
};

// Auto-rotate cards every 3 seconds
useEffect(() => {
  const interval = setInterval(next, 3000);
  return () => clearInterval(interval);
}, []);
const headlineimages = [
  
  [
   NYC
  ],
];



const headlinelinks = [
 
  ['https://www.nyctourism.com/']
];
const headlineheadings = ['Headline Partner'];


const eventimages = [
  
  [
   MoxyLogoBlack, WytheLogoBlack, PennyLogoBlack, ArloLogoBlack, HoxtonLogoBlack
  ],
];



const eventlinks = [
 
  ['https://www.marriott.com/en-us/hotels/nycxb-moxy-brooklyn-williamsburg/overview/', 'https://www.wythehotel.com/', 'https://www.penny-hotel.com/', 'https://arlohotels.com/williamsburg/?gad_source=1&gclid=Cj0KCQjwztOwBhD7ARIsAPDKnkAkYDb_Mq1gtOXwvvdPqHcqTyhRd79ai-25PXNGBSGkkEGkOQhrkygaAqeREALw_wcB&gclsrc=aw.ds', 'https://thehoxton.com/williamsburg/']
];
const eventheadings = ['Event Partners'];


return (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>m&i Fest Global, Marrakech     </title>
      <meta
        name="description"
        content="Elevate your business at the first ever MICE networking event in Brooklyn, NYC"
      />
      <meta
        property="og:url"
        content={
          "https://www.mi-forums.com/images/ographs/OG_image_Europe_Autumn_2022.png"
        }
      />
      <meta
        property="og:image"
        content={
          "https://www.mi-forums.com/images/ographs/OG_image_Europe_Autumn_2022.png"
        }
      />
    </Helmet>
    <div className="site-wrap">
      <div className="event-page twentyfour fest25 powder-bg">
      <div
            className="sprint-hero-slider">


<div class="video-container">

<iframe class="hero-background-video" src="https://www.youtube.com/embed/AHVlHdylJHU?si=GpKfCLeH55uc6lFd?rel=0&amp;autoplay=1&amp;mute=1&amp;playsinline=1&amp;controls=0&amp;showinfo=0&amp;loop=1&amp;playlist=AHVlHdylJHU" frameborder="0" allowfullscreen="allowfullscreen"></iframe>
        
    
        </div>

     

<div className="row no-gutters align-items-stretch">
                <div className="header-section-heading">
                <img className="festlogo" src={Festlogo}/><br/>
                 <h4>16-20 March 2025</h4><br/>
                 <h3>DEVELOP. EXPLORE. CELEBRATE.</h3>
                 <a href="#/" onClick={openModal} >
        {modal ? (
          <section className="modal__bg">
            <div className="modal__align">
              <div className="modal__content" modal={modal}>
                
                <div className="modal__video-align">
                  {videoLoading ? (
                    <div className="modal__spinner">
                    
                    </div>
                  ) : null}
                  <iframe
                              className="modal__video-style"
                              title="People of MI"
                    onLoad={spinner}
                    loading="lazy"
                    width="800"
                    height="500"
                    src="https://www.youtube.com/embed/vMn-wJ5nHC8?si=BE5XmxZQv0edwmfQ"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </section>
        ) : null}
        <div className="peopleofmi-video-container">
        <img className="watchrecap" onClick={openModal} src={Watchrecap}/><br/>
             </div></a>

                
               
               
                </div>
            </div>


       
        </div>

        <Sticky>
          <div className="event-under-banner twentyfour raven-bg" id="scrollto">
            <div className="container">
              <div className="row no-gutters align-items-stretch">
                <div className=" ml-rg-auto event-sticky-text">
                  <h3>Love the sound of this event?</h3>
                </div>
                <div className="ml-rg-auto event-buttons flex-wrap ">
                  <a href="/tell-me-more">
                    <span className=" btn btn-secondary transparent">
                      Tell me more
                    </span>
                  </a>
                  <a href="/signup">
                    <span className=" btn btn-secondary grey">
                    Register now
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Sticky>
     
        <div className="site-half eventInformationSection" >
          <div className="container">
            <div className="row no-gutters center-content">
           
            <div className="col-lg-10 mr-lg-auto py-5" style={{paddingRight:'20px'}}>
              <img className="mandala" src={Pattern1}></img><br/><br/><br/>
              <h2>The must-attend event for MICE</h2>
              <br/>
              <p>
              Discover the boutique exhibition that's got the industry talking - and our most successful event to date. Launched in 2024, m&i Fest is a fresh take on the traditional MICE trade show, combining the choice of an exhibition with the proven ROI power of our Forums.

               </p>
            </div>

            </div>
          </div>
          <div className="anchorScroll" id="numbers"></div>
          <div className="mandala-wrapper">
        <img src={MandalaShape} className="mandala-shape"></img>
      </div>
        </div>

        {/* <div className="numbers-section eventNumbers powder-bg">
          <div className="container">
            <div className="row no-gutters align-items-stretch">
              <div
                className="col-lg-12 ml-rg-auto py-4 laptop"
                style={{ color: "#fff" }}
              >
                <div className="container">
                  <div className="inNumbers">
                  
                    <ul>
                      <li>
                        <div className="numbers-caption ">
                          <h1 className="eventNumbers raven">3</h1>
                          <p>Days</p>
                        </div>
                      </li>
                      <li>
                        <div className="numbers-caption">
                          <h1 className="eventNumbers raven">40</h1>
                          <p>1-2-1 Meetings</p>
                        </div>
                      </li>
                      <li>
                        {" "}
                        <div className="numbers-caption">
                          <h1 className="eventNumbers raven">90</h1>
                          <p>Supplier tables</p>
                        </div>
                      </li>
                      <li>
                        <div className="numbers-caption">
                          <h1 className="eventNumbers raven">100</h1>
                          <p>American buyers</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="anchorScroll" id="spotlight"></div>
          </div>
        </div> */}
       


<div className="site-half missSection powder-bg">
        <h2>Why you won’t want to miss m&i Fest</h2>
          <div className="container">
            <div className="row no-gutters align-items-stretch">
             
           
            <div
              className="col-lg-4 ml-rg-auto py-5"
            >
               <div className="programmeItem">
                 <img
              alt={" "}
              src={Image1}
              className={"sampleThumbnails"}
            ></img>
             <h1 className="itemHeading">Attracting the industry’s best</h1>
             <p className="itemCaption">m&i Fest attracts the highest-quality MICE buyers & suppliers. All our attendees are rigorously reference checked to ensure we bring you only the most valuable business.  </p>
              </div>
              </div>

            
              <div
              className="col-lg-4 ml-rg-auto py-5"
            >
                <div className="programmeItem ">
                 <img
              alt={" "}
              src={Image2}
              className={"sampleThumbnails"}
            ></img>
            <h1 className="itemHeading">The highest ROI per meeting</h1>
             <p className="itemCaption">Meetings at m&i are the best in the business. You get a personalised meeting schedule matched by AI technology, and the support of our onsite meeting management team, ensuring a less than 1% buyer no-show rate. The lowest in the industry.   </p>
              </div>
              </div>

             
              <div
              className="col-lg-4 ml-rg-auto py-5"
            >
               <div className="programmeItem ">
                 <img
              alt={" "}
              src={Image3}
              className={"sampleThumbnails"}
            ></img>
            <h1 className="itemHeading">A programme built for business</h1>
             <p className="itemCaption">Every moment of the event programme is designed to create connections and give you unlimited access; from your curated meeting schedule to inspiring networking afternoons and spectacular evenings. </p>
              </div>
              </div>

            </div>
          </div>
        </div>

        <div className="site-half sampleProgramme raven-bg">
        <h1>Built to maximise brand visibility</h1>
        <p>Tell your brand story</p>
          <div className="container">
            <div className="row no-gutters align-items-stretch">
             
           
            <div
              className="col-lg-4 ml-rg-auto py-5"
            >
               <div className="programmeItem">
                 <img
              alt={" "}
              src={Image4}
              className={"sampleThumbnails"}
            ></img>
          
              </div>
              </div>

            
              <div
              className="col-lg-4 ml-rg-auto py-5 mobileHide"
            >
                <div className="programmeItem">
                 <img
              alt={" "}
              src={Image5}
              className={"sampleThumbnails"}
            ></img>
           
              </div>
              </div>

             
              <div
              className="col-lg-4 ml-rg-auto py-5 mobileHide"
            >
               <div className="programmeItem">
                 <img
              alt={" "}
              src={Image6}
              className={"sampleThumbnails"}
            ></img>
           
              </div>
              </div>

              <p>Fest provides you with a creative platform unlike any other in the MICE market. We'll help you get the attention of the industry's best buyers - your brand, your way. </p>

            </div>
          </div>
          <img src={CutShape} className="cutshape-1"></img>
        </div>
       
        <div className="row splitrow explore" style={{backgroundColor:"#FBEFE7"}}>
                      <div class="column leftSide afternoons"  >
                      <div className='col-lg-12 ml-rg-auto py-5'>
                            
                      <div className="container">
          <div className="row no-gutters align-items-stretch">
            <div
              className="col-lg-7 ml-rg-auto "
            >
             
              <h1 className="site-section-heading font-secondary">
              Talk to us about a branded Studio or Pavilion
              </h1>
             
          
              <p
                  
                  className="mobilePadding-2"
                >
                  <ul>
     <li>More brand visibility and exposure</li>
     <br/>
     <li>More facetime with buyers</li>
     <br/>
     <li>Exclusive brand activations to stand out from the crowd</li>
     <br/>
     <li>Marketing and promotional support</li>
     <a href="/signup">
                  <span className="btn btn-secondary raven">Get in touch</span>
                </a>
     </ul>
                </p>
               
                </div>
          </div>
        </div>
                          
                          
                          </div>

          </div>

              <div className="column rightSide afternoons" style={{ backgroundImage: `url(${Image7})` }}>
                          <div className='col-lg-12 ml-rg-auto py-5'>
                        
                        
                          </div>
              </div>
      </div>



      <div className="site-half pressSection">
        <h2>Hot off the press</h2>
          <div className="container">
            <div className="row no-gutters align-items-stretch">
             
           
            <div
              className="col-lg-4 ml-rg-auto py-5"
            >
               <div className="programmeItem">
               <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill="#F77441" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 379.51" 
               style={{width:"26px",display:"block",margin:"auto", paddingBottom:"33px"}}>
    <path d="M212.27 33.98C131.02 56.52 78.14 103.65 64.99 185.67c-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160.01 60.56 27.12 149.48-159.79 175.36-215.11 92.8-12.87-19.19-21.39-41.59-24.46-66.19C-11.35 159.99 43.48 64.7 139.8 19.94c17.82-8.28 36.6-14.76 56.81-19.51 10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78zm267.78 0c-81.25 22.54-134.14 69.67-147.28 151.69-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160 60.56 27.13 149.48-159.78 175.36-215.1 92.8-12.87-19.19-21.39-41.59-24.46-66.19C256.43 159.99 311.25 64.7 407.58 19.94 425.4 11.66 444.17 5.18 464.39.43c10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78z"/></svg>
    <a href="https://kongres-magazine.eu/2024/03/mi-fest-ushering-in-a-new-era-for-trade-shows/">
                 <img
              alt={" "}
              src={Kongres}
              className={"sampleThumbnails"}
            ></img></a>
             
             <p className="itemCaption">"m&i Fest ushered in a new era for trade shows. It was a masterclass in pairing effective in-person meetings with unparalleled destination tours"</p>
             <h1 className="itemHeading">Kongres Magazine</h1>
              </div>
              </div>

            
              <div
              className="col-lg-4 ml-rg-auto py-5"
            >
                <div className="programmeItem">
                <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill="#F77441" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 379.51" 
               style={{width:"26px",display:"block",margin:"auto", paddingBottom:"33px"}}>
    <path d="M212.27 33.98C131.02 56.52 78.14 103.65 64.99 185.67c-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160.01 60.56 27.12 149.48-159.79 175.36-215.11 92.8-12.87-19.19-21.39-41.59-24.46-66.19C-11.35 159.99 43.48 64.7 139.8 19.94c17.82-8.28 36.6-14.76 56.81-19.51 10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78zm267.78 0c-81.25 22.54-134.14 69.67-147.28 151.69-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160 60.56 27.13 149.48-159.78 175.36-215.1 92.8-12.87-19.19-21.39-41.59-24.46-66.19C256.43 159.99 311.25 64.7 407.58 19.94 425.4 11.66 444.17 5.18 464.39.43c10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78z"/></svg>
                 <img
              alt={" "}
              src={Travel}
              className={"sampleThumbnails"}
            ></img>
          
             <p className="itemCaption">"m&i Fest Revolutionizes MICE Trade Shows with New Event Format in Marrakech"</p>
             <h1 className="itemHeading">Travel Weekly - Mena</h1>
              </div>
              </div>

             
              <div
              className="col-lg-4 ml-rg-auto py-5"
            >
               <div className="programmeItem">
               <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill="#F77441" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 379.51" 
               style={{width:"26px",display:"block",margin:"auto", paddingBottom:"33px"}}>
    <path d="M212.27 33.98C131.02 56.52 78.14 103.65 64.99 185.67c-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160.01 60.56 27.12 149.48-159.79 175.36-215.11 92.8-12.87-19.19-21.39-41.59-24.46-66.19C-11.35 159.99 43.48 64.7 139.8 19.94c17.82-8.28 36.6-14.76 56.81-19.51 10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78zm267.78 0c-81.25 22.54-134.14 69.67-147.28 151.69-3.58 22.32 1.42 5.46 16.55-5.86 49.4-36.96 146.53-23.88 160 60.56 27.13 149.48-159.78 175.36-215.1 92.8-12.87-19.19-21.39-41.59-24.46-66.19C256.43 159.99 311.25 64.7 407.58 19.94 425.4 11.66 444.17 5.18 464.39.43c10.12-2.05 17.47 3.46 20.86 12.77 2.87 7.95 3.85 16.72-5.2 20.78z"/></svg>
    <a href="
https://www.c-mw.net/all-on-board-the-mis-mice-express-in-Marrakech-24/">
                 <img
              alt={" "}
              src={CMW}
              className={"sampleThumbnails"}
            ></img></a>
           
             <p className="itemCaption">“m&i Fest is their blueprint for their future of events - a modern caravanserai for conferencing. It is buyer-led, meaning that the organisers have an intuitive ‘ear to the ground’ for what they want”. </p>
             <h1 className="itemHeading">CMW</h1>
              </div>
              </div>

            </div>
          </div>
        </div>

        <div className="site-half block whattoexpect" style={{ paddingBottom: "5rem" }}>
          <div className="img-bg-1 mobileHide" >
            <img alt={" "} src={Whattoexpect} className={"network"} style={{width:"430px"}} />
          </div>
          <div className="container">
            <div className="row no-gutters align-items-stretch">
              <div
                className="col-lg-6 ml-rg-auto "
              >
                <h2 className="site-section-heading font-secondary">
                What to expect
                </h2>
                <p
                    style={{ paddingRight: "20px" }}
                    className="mobilePadding-2"
                  >
             <ul>

              <li><span >4</span> days of connections</li>
              <hr></hr>
              <li><span >40</span> curated 1-2-1 meetings</li>
            
              <hr></hr>
              <li><span >55k</span> connections</li>
              <hr></hr>
              <li><span >250</span> suppliers, including global MICE destinations and international hotel brands </li>
              <hr></hr>
              <li><span >320</span> buyers, of the highest-quality EMEA and international buyers </li>
              <hr></hr>
             </ul>
                  </p>
              </div>
            </div>
           
          </div>
          <img src={CutShape2} className="cutshape-2"></img>
        </div>
      {/* <div className="site-half section-title powder-bg" >
          <div className="container">
            <div className="row no-gutters">
           
            <div className="col-lg-12 mr-lg-auto py-5">
              <h2>Take a look below to see our accommodation and meetings venues. </h2>
             
            </div>

            </div>
          </div>
        </div> */}



<div className="row splitrow explore" style={{backgroundColor:"#FBEFE7"}}>
                      <div class="column leftSide afternoons"  >
                      <div className='col-lg-12 ml-rg-auto py-5'>
                            
                      <div className="container">
          <div className="row no-gutters align-items-stretch">
            <div
              className="col-lg-7 ml-rg-auto "
            >


              <img src={MorningsVector} style={{padding:"0 0 8vh 0"}}></img>
              <br></br>
             
              <h1 className="site-section-heading font-secondary">
            Mornings of meetings
              </h1>
             
          
              <p
                  
                  className="mobilePadding-2"
                >
                 Engage in 40 curated 1-2-1 meetings with support from our onsite meeting management team.
                </p>
               
                </div>
          </div>
        </div>
                          
                          
                          </div>

          </div>

              <div className="column rightSide afternoons" style={{ backgroundImage: `url(${Mornings})` }}>
                          <div className='col-lg-12 ml-rg-auto py-5'>
                        
                        
                          </div>
              </div>
      </div>

      <div className="row splitrow celebrate" style={{backgroundColor:"#352E35"}}>
                      <div class="column leftSide celebrate" style={{ backgroundImage: `url(${Afternoons})` }}>
                      <div className='col-lg-12 ml-rg-auto py-5'>
                            
                          </div>

          </div>

          <div className="column rightSide celebrate powder-bg">
          <div className='col-lg-12 ml-rg-auto py-5'>
                            
                            <div className="container">
                <div className="row no-gutters align-items-stretch">
                  <div
                    className="col-lg-7 ml-rg-auto "
                  >
      
      
                    <img src={AfternoonVector} style={{padding:"0 0 8vh 0"}}></img>
                    <br></br>
                   
                    <h1 className="site-section-heading font-secondary">
                 Afternoons of adventure
                    </h1>
                   
                
                    <p
                        
                        className="mobilePadding-2"
                      >
                   Build authentic connections and see the MICE highlights of the destination through 'Pathways' of cultural activities and exclusive site visits.
                      </p>
                     
                      </div>
                </div>
              </div>
                                
                                
                                </div>
      </div>

     
     
              {/* <button onClick={prevSlide}>Previous</button>
      <button onClick={nextSlide}>Next</button> */}
      </div>

      <div className="row splitrow explore nights" style={{backgroundColor:"#121A36"}}>
                      <div class="column leftSide afternoons"  >
                      <div className='col-lg-12 ml-rg-auto py-5'>
                            
                      <div className="container">
          <div className="row no-gutters align-items-stretch">
            <div
              className="col-lg-7 ml-rg-auto "
            >


              <img src={NightsVector} style={{padding:"0 0 8vh 0"}}></img>
              <br></br>
             
              <h1 className="site-section-heading font-secondary">
           Nights of networking
              </h1>
             
          
              <p
                  
                  className="mobilePadding-2"
                >
            Lasting business relationships are built through unforgettable experiences. Every evening, enjoy spectacular entertainment at stunning venues.
                </p>
               
                </div>
          </div>
        </div>
                          
                          
                          </div>

          </div>

              <div className="column rightSide afternoons" style={{ backgroundImage: `url(${Nights})` }}>
                          <div className='col-lg-12 ml-rg-auto py-5'>
                        
                        
                          </div>
              </div>
      </div>

      <div className="site-half eventInformationSection powder-bg" >
            <div className="container">
              <div className="row no-gutters center-content">
             
              <div className="col-lg-10 mr-lg-auto py-5" style={{paddingRight:'20px'}}>
                <h2>Marrakech magic</h2>
                <br/>
                <p>
               With its year-round sunny climate, excellent MICE and hotel infrastructure, and incentive options, Morocco has long been established as a global MICE destination - the perfect home for m&i Fest.
               <br/><br/>
               Discover stunning venues around Marrakech. From the city's exotic souks to its rich cultural heritage, explore a breathtakingly beautiful destination that perfectly blends tradition and modernity.
                </p>
              </div>

              </div>
            </div>
            <div className="anchorScroll" id="numbers"></div>
          </div>

          <div className="row splitrow celebrate" style={{backgroundColor:"#352E35", height:"auto"}}>
                      <div class="column leftSide celebrate" style={{ backgroundImage: `url(${Movenpick})` }}>
                      <div className='col-lg-12 ml-rg-auto py-5'>
                            
                          </div>

          </div>

          <div className="column rightSide celebrate hostvenue raven-bg">
          <div className='col-lg-12 ml-rg-auto py-5'>
                            
                            <div className="container">
                <div className="row no-gutters align-items-stretch">
                  <div
                    className="col-lg-10 ml-rg-auto "
                  >
      
      
                 
                    <br></br>
                   
                    <h3
                                        className='site-section-heading font-secondary '
                                        style={{ textDecoration: 'underline' }}
                                    >
                                       Our Headline Partner 
                                    </h3>
                   
                <img src={Movenpicklogo} style={{width:'250px'}}></img>
                <br/><br/>
                    <p
                        
                        className="mobilePadding-2" style={{color: '#fff'}}
                      >
                    Giving you an authentic welcome to the captivating Red City of Marrakech, Mövenpick Hotel Mansour Eddahbi Marrakech is located just a few minutes away from the bustling Medina in the central L'Hivernage district.
<br/><br/>
With 503 contemporary hotel rooms and suites, five superb restaurants, the Ô de Rose spa, and a rooftop lounge serving a range of cuisines from traditional Moroccan to divine Mediterranean, you’ll find that there’s plenty for you to enjoy.
                      </p>
                      <a href="https://movenpick.accor.com/en/africa/morocco/marrakech/marrakech.html">
                    <span className=" btn btn-secondary bambi">
                   Visit website
                    </span>
                    </a>
                     
                      </div>
                </div>
              </div>
                                
                                
                                </div>
      </div>

     
     
           
      </div>

      {/* <div className="site-half eventInformationSection bambi-bg" >
            <div className="container">
              <div className="row no-gutters center-content">
             
              <div className="col-lg-10 mr-lg-auto py-5" style={{paddingRight:'20px'}}>
              <h3
                                        className='site-section-heading font-secondary '
                                        style={{ textDecoration: 'underline' }}
                                    >
                                       What our community is saying
                                    </h3>
                <br/>
              
              </div>

              </div>
            </div>
            <Carousel items={cards} />
           
          </div> */}


          <div className="site-half powder-bg" >
            <div className="container">
            <h2 style={{textAlign:"center"}}>The numbers don't lie</h2>
              <div className="grid-container">
                
      {gridData.map((item) => (
        <div key={item.id} className="grid-item">
          <h1>{item.value}</h1>
          <p>{item.description}</p>
        </div>
      ))}
    </div>
              
            </div>
           
          </div>

    


         
    

      {/* <div className="site-half block" style={{ paddingBottom: "5rem" }}>
      <div id="scroll-container">
<div id="scroll-text">Meet with a wider range of MICE professionals at our Flagship Forum in Lisbon. </div>
</div>
  </div> */}

    

  {/* <div className='event-venue'>
                      <div className='container'>
                          <div className='row no-gutters align-items-stretch'>
                            

                          </div>
                          <div className='anchorScroll' id='itinerary'></div>
                      </div>
                  </div>  */}

                  

      


        
        <div className="packages-section bambi-bg">
          <div className="container">
            <div className="row no-gutters align-items-stretch">
              <div className="col-lg-12 ml-lg-auto py-5 ">

                <h2 className="site-section-heading font-secondary">
                  <span style={{fontSize:"20px"}}>
                    What's in it for you?
                  </span>
                </h2>

                <div className="tabs">
      <div
        className={`tab ${activeTab === 'suppliers' ? 'active' : ''}`}
        onClick={() => toggleAccordion('suppliers')}
      >
        Suppliers
      </div>
      <div className="separator">|</div>
      <div
        className={`tab ${activeTab === 'buyers' ? 'active' : ''}`}
        onClick={() => toggleAccordion('buyers')}
      >
        Buyers
      </div>
    </div>
    <div className={`accordion ${activeTab === 'suppliers' ? 'open' : ''}`}>
      {/* Suppliers content */}
      <div className="content">

      <ul className='packages'>

       

 
      <p>Want to get the attention of the industry’s best buyers at events that offer the highest converting RFPs? Let’s work together to get you more buyer facetime, maximum brand visibility, and more genuine connections. We’ll help you showcase your business in powerful ways.  </p><br/>
 




                                                      <li>
                                                    4 days of quality networking with top international buyers
                                                      </li>
                                                      <li>
                                                      40 one-to-one meetings, saving you weeks of research and requesting appointments 
                                                      </li>
                                                      <li>
                                                      Cutting-edge AI-matchmaking technology to find your best meeting potential with buyers who have business for you
                                                      </li>
                                                      <li>
                                                      Afternoon ‘Pathways’ of networking activities maximise your interactions with buyers and a full social programme helps you build more authentic relationships    
                                                      </li>
                                                      <li>Celebrate and create closer connections with buyers at m&i Fest evenings </li>
                                                      <li>Personalised branded exhibitor spaces, and enhanced visibility and sponsorship opportunities </li>
                                                     
                                                      <li>
                                                     A dedicated Account Manager to guide you from start to finish
                                                      </li>
                                                      {/* <li>
                                                     New to m&i and wondering what to expect at our events? See <a href='https://mi-forums.com/supplier-experience'>
                                                              how it works.
                                                          </a>
                                                      </li> */}
                                                  </ul>

        
      </div>
    </div>
    <div className={`accordion ${activeTab === 'buyers' ? 'open' : ''}`}>
      {/* Buyers content */}
      <div className="content">

     
      <ul className='packages'>

        <p>Explore Marrakech’s MICE infrastructure & fascinating culture through curated ‘Pathway’ activities   </p><br/>

                                                      <li>
                                                     4 days of destination discovery and quality networking with top global suppliers  
                                                      </li>
                                                      <li>See how the destination hosts international events through the eyes of an attendee  </li>
                                                      <li>A personalised schedule of 40 one-to-one meetings   </li>
                                                      <li>
                                                      Cutting-edge AI matchmaking technology to find your best business potential with the suppliers you want to meet   
                                                      </li>

                                                      <li>
                                                      Explore Marrakesh’s MICE infrastructure & fascinating culture – get more choice than ever before with ‘Pathways’ of activities 
                                                      </li>
                                                      <li>
                                                      A hosted buyer package including accommodation, transfers, F&B, and flight allowance 
                                                      </li>
                                                      <li>Live your best Fest life! Celebrate and create closer connections with suppliers at m&i Fest evenings </li>
                                                      {/* <li>
                                                      Glamorous parties and nightlife experiences 
                                                      </li>
                                                     

                                                    
                                                      <li>
                                                          Find out how we're{' '}
                                                          <a href='https://www.mi-forums.com/covid-safe-events'>
                                                              keeping you safe
                                                          </a>{' '}
                                                          at this event
                                                      </li>
                                                      <li>
                                                          New to m&i?
                                                          Wondering what to
                                                          expect at our events
                                                          ? See{' '}
                                                          <a href='https://mi-forums.com/supplier-experience'>
                                                              how it works
                                                          </a>
                                                      </li> */}
                                                  </ul>
      </div>
    </div>

    
            
               
              </div>
            
            </div>
          </div>
        </div>

        {/* <div className="site-half brooklyn partnerSection">
          <div className="container">
            <div className="row no-gutters align-items-stretch">
              <div className="col-lg-12 ml-rg-auto py-5">
                <h3 className="site-section-heading font-secondary">
                  
                </h3>

                <Carousel images={eventimages} links={eventlinks} headings={eventheadings} autoPlay />
                
              </div>
            </div>
          </div>
        </div> */}

        <div
          className="cta-banner"
          style={{ backgroundImage: `url(${CTA})` }}
        >
          <div className="container">
            <div className="row no-gutters align-items-stretch">
              <div className="col-lg-12 ml-rg-auto py-5 laptop">
                <div className="cta-content">
                <div
                  className="footer-section-heading "
               
                >
                <h3>m&i Marrakech 25</h3>
                 <h1>Elevate your MICE business in 2025.   </h1>
                 <h3 className="caption">Save your space today. </h3>
                </div>
                  <a href="/signup">
                    <span className=" btn btn-primary">
                    See if you qualify
                    </span>
                  </a>
                  <br/><br/><br/>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      


      
      </div>
    </div>
  </>
);
};

export default Fest25;
