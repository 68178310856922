import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import Carousel from "../../components/elements/Carousel"
import Sticky from "react-sticky-el";
import "../../assets/scss/style.scss";
import EpicSana from "../../assets/images/events/partner_logos/EpicSana.jpg"
import Connect from "../../assets/images/events/2025/dubai/business.png";
import Experience from "../../assets/images/events/2025/dubai/meetings.png";
import Enjoy from "../../assets/images/events/2025/dubai/nights.png";
import CTA from "../../assets/images/events/2025/dubai/cta.png";
import Spotlight from "../../assets/images/events/2025/dubai/spotlight.png";
import Accommodation from "../../assets/images/events/2025/dubai/accommodation.png";
import PortugalTravel from "../../assets/images/events/partner_logos/Portugal_Travel.jpg";
import Vega from "../../assets/images/events/partner_logos/vega_logo.png";
import Fairmont from "../../assets/images/events/2025/dubai/scandic.png";
import FairmontB from  "../../assets/images/events/2025/lakelouise/fairmont-b.png";
import BLL from  "../../assets/images/events/2025/lakelouise/BLL.png";
import Header from "../../assets/images/events/2025/dubai/header.png";
import RareIndigo from "../../assets/images/events/partner_logos/rareindigo.jpg"
import MariottBlack from "../../assets/images/events/2025/dubai/mariott-black.png";
import MariottWhite from "../../assets/images/events/2025/dubai/mariott-white.svg";

const Dubai = () => {

  
  const slides = [
    {
      backgroundImage: `url(${Accommodation})`,
      title: 'Accommodation and meeting venue',
      imageSrc: MariottWhite,
      content: 'Soaring above Sheikh Zayed Road, just minutes from the Burj Khalifa and Dubai Opera, our host venue is the world’s tallest 5-star hotel, boasting awe-inspiring views of the water and Dubai’s famed cityscape. Within its impressive exteriors you can enjoy 12 restaurants and lounges serving diverse cuisines, 1,608 rooms, and 8000 sq m of adaptable event space, including a stunning ballroom and outdoor venues.  ',
      website: 'https://www.marriott.com/en-us/hotels/dxbjw-jw-marriott-marquis-hotel-dubai/overview/ ',
      width: '200px', 
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  
  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % slides.length);
  };
  
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((currentSlide + 1) % slides.length);
    }, 5000);
  
    return () => clearInterval(intervalId);
  }, [currentSlide, slides.length]);
  
  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + slides.length) % slides.length);
  };
  
  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  
    const [activeTab, setActiveTab] = useState('suppliers'); // Set the initial active tab to 'suppliers'

    const toggleAccordion = (tabName) => {
      setActiveTab(activeTab === tabName ? null : tabName);
    };

    const videoEl = useRef(null);

const attemptPlay = () => {
  videoEl &&
    videoEl.current &&
    videoEl.current.play().catch(error => {
      console.error("Error attempting to play", error);
    });
};
const handleScroll = () => {
      const sectionElement = document.getElementById('scrollto');
      // If you want to scroll to a specific section by its ID

    // const percentageToScroll = 0.5;
    // const offset = window.innerHeight * percentageToScroll;
    // If you want to scroll a certain percentage down the page

    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
   
  const textRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    
      

  useEffect(() => {

    
attemptPlay();

   

   
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);

     
    };

    
  }, []);

  const images = [
    
    [
      MariottBlack]
  ];
 

  
  const links = [
   
    ['https://www.marriott.com/en-us/hotels/dxbjw-jw-marriott-marquis-hotel-dubai/overview/ ']
  ];
  const headings = ['Headline Partner'];


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>m&i Flagship Dubai | UK & EU buyers     </title>
        <meta
          name="description"
          content="The ultimate B2B experience for the luxury incentive travel industry  "
        />
        <meta
          property="og:url"
          content={
            "https://www.mi-forums.com/images/ographs/OG_image_Europe_Autumn_2022.png"
          }
        />
        <meta
          property="og:image"
          content={
            "https://www.mi-forums.com/images/ographs/OG_image_Europe_Autumn_2022.png"
          }
        />
      </Helmet>
      <div className="site-wrap">
        <div className="event-page twentyfour lakelouise powder-bg">
          <div
            className="sprint-hero-slider" style={{
            // backgroundImage: `url(${Header})`,
          }}>

<div class="video-container">
    <iframe class="hero-background-video" 
            src="https://www.youtube.com/embed/b7s79-8QoIM?rel=0&amp;autoplay=1&amp;mute=1&amp;playsinline=1&amp;controls=0&amp;showinfo=0&amp;loop=1&amp;playlist=b7s79-8QoIM" 
            frameborder="0" 
            allowfullscreen="allowfullscreen">
    </iframe>
</div>


<div className="row no-gutters align-items-stretch">
                  <div className="header-section-heading">
                  {/* <h3>Flagship</h3> */}
                   <h1>m&i Flagship Dubai </h1>
                   <h3>UK & EU buyers      </h3>
                   <h4>30 Nov - 4 Dec | UAE </h4>
                   <div className="scroll-arrow" onClick={handleScroll} >
     <svg width="70" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" fill="#E0C7B2">
<g><path d="M500,10"/><path d="M500,990L108,598h784L500,990z"/></g>
</svg>
    </div>
                 
                  </div>
              </div>


         
          </div>

          <Sticky>
            <div className="event-under-banner twentyfour raven-bg" id="scrollto">
              <div className="container">
                <div className="row no-gutters align-items-stretch">
                  <div className=" ml-rg-auto event-sticky-text">
                    <h3>Love the sound of this event?</h3>
                  </div>
                  <div className="ml-rg-auto event-buttons flex-wrap ">
                    <a href="/tell-me-more">
                      <span className=" btn btn-secondary transparent">
                        Tell me more
                      </span>
                    </a>
                    <a href="/signup">
                      <span className=" btn btn-secondary grey">
                      Register now
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Sticky>
       
          <div className="site-half eventInformationSection powder-bg" >
            <div className="container">
              <div className="row no-gutters center-content">
             
              <div className="col-lg-10 mr-lg-auto py-5" style={{paddingRight:'20px'}}>
                <h2>Grow your industry connections <br/>in a renowned MICE destination </h2>
                <br/>
                <p>
                The gold standard in MICE events, our original event format offers unrivalled ROI and a greater choice of meetings. Created for all MICE industry needs, Flagship Dubai 25 will bring together top MICE buyers and suppliers for three days of 1-2-1 meetings and networking opportunities. 
                </p>
              </div>

              </div>
            </div>
            <div className="anchorScroll" id="numbers"></div>
          </div>

          <div className="numbers-section eventNumbers powder-bg">
            <div className="container">
              <div className="row no-gutters align-items-stretch">
                <div
                  className="col-lg-12 ml-rg-auto py-4 laptop"
                  style={{ color: "#fff" }}
                >
                  <div className="container">
                    <div className="inNumbers">
                    
                      <ul>
                        <li>
                          <div className="numbers-caption ">
                            <h1 className="eventNumbers raven">3</h1>
                            <p>Days</p>
                          </div>
                        </li>
                        <li>
                          <div className="numbers-caption">
                            <h1 className="eventNumbers raven">40</h1>
                            <p>1-2-1 meetings</p>
                          </div>
                        </li>
                        <li>
                          {" "}
                          <div className="numbers-caption">
                            <h1 className="eventNumbers raven">150</h1>
                            <p>Supplier tables</p>
                          </div>
                        </li>
                        <li>
                          <div className="numbers-caption">
                            <h1 className="eventNumbers raven">160</h1>
                            <p>UK & European buyers</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="anchorScroll" id="spotlight"></div>
            </div>
          </div>


         
          <div className="row splitrow explore" style={{backgroundColor:"#ECDDD1"}}>
                        <div class="column leftSide afternoons"  >
                        <div className='col-lg-12 ml-rg-auto py-5'>
                              
                        <div className="container">
            <div className="row no-gutters align-items-stretch">
              <div
                className="col-lg-7 ml-rg-auto "
              >
               
                <h1 className="site-section-heading font-secondary">
                Spotlight on Dubai
                </h1>
               
            
                <p
                    
                    className="mobilePadding-2"
                  >
    A vibrant hub of innovation, Dubai has long been recognised as a preferred MICE destination. Its unparalleled access to emerging markets, multicultural environment, and history of hosting global gatherings promises an empowering experience for delegates. 
                    <br />
                    <br />
                    You’ll never be short on world-class MICE properties and venues with state-of-the-art facilities and sustainable certification. Whether you’re in the market for a luxury hard-brand resort or an ultra-stylish beachfront property, you’ll find there’s a venue for every MICE group. 
                  </p>
                 
                  </div>
            </div>
          </div>
                            
                            
                            </div>

            </div>

                <div className="column rightSide afternoons" style={{ backgroundImage: `url(${Spotlight})` }}>
                            <div className='col-lg-12 ml-rg-auto py-5'>
                          
                          
                            </div>
                </div>
        </div>

        <div className="site-half section-title powder-bg" >
          <div className="container">
            <div className="row no-gutters">
           
            <div className="col-lg-12 mr-lg-auto py-5">
              <h2>Take a look below to see our accommodation and meetings venues. </h2>
             
            </div>

            </div>
          </div>
        </div>

      <div className="row splitrow celebrate" style={{backgroundColor:"#352E35"}}>
                      <div class="column leftSide celebrate" style={{ backgroundImage: slides[currentSlide].backgroundImage }}>
                      <div className='col-lg-12 ml-rg-auto py-5'>
                            
                          
                          
                          </div>

          </div>

          <div className="column rightSide celebrate">
        <div className='col-lg-12 ml-rg-auto py-5'>
          <div className="container carousel-content-container">
            <div className='col-lg-12 ml-lg-auto py-5' style={{ textAlign: 'center' }}>
              <h3 className='site-section-heading font-secondary' style={{ textDecoration: 'underline' }}>
                {slides[currentSlide].title}
              </h3>
              <div className="carousel-accommodation">
                <div className="carousel-accommodation-logo">
                  <a href={slides[currentSlide].website} target="_blank">
                    <img alt=" " src={slides[currentSlide].imageSrc} className="carousel-accommodationlogo" style={{ width: slides[currentSlide].width }}></img>
                  </a>
                </div>
                <br />
                <p>{slides[currentSlide].content}</p>
                <a href={slides[currentSlide].website} target="_blank">
                  <span className="btn btn-secondary transparent">Visit website</span>
                </a>
             
              </div>
                 
            </div>
          </div>
        </div>
      </div>

     
     
              {/* <button onClick={prevSlide}>Previous</button>
      <button onClick={nextSlide}>Next</button> */}
      </div>


        <div className="site-half partnerSection">
            <div className="container">
              <div className="row no-gutters align-items-stretch">
                <div className="col-lg-12 ml-rg-auto py-5">
                  <h3 className="site-section-heading font-secondary">
                    
                  </h3>

                  <Carousel images={images} links={links} headings={headings} autoPlay />
                  
                </div>
              </div>
            </div>
          </div>

      

        {/* <div className="site-half block" style={{ paddingBottom: "5rem" }}>
        <div id="scroll-container">
  <div id="scroll-text">Meet with a wider range of MICE professionals at our Flagship Forum in Lisbon. </div>
</div>
    </div> */}

      

    {/* <div className='event-venue'>
                        <div className='container'>
                            <div className='row no-gutters align-items-stretch'>
                              

                            </div>
                            <div className='anchorScroll' id='itinerary'></div>
                        </div>
                    </div>  */}

          <div className="site-half sampleProgramme raven-bg">
          <h1>What to expect</h1>
            <div className="container">
              <div className="row no-gutters align-items-stretch">
               
             
              <div
                className="col-lg-4 ml-rg-auto py-5"
              >
                 <div className="programmeItem">
                   <img
                alt={" "}
                src={Connect}
                className={"sampleThumbnails"}
              ></img>
               <h1 className="itemHeading">Network</h1>
               <p className="itemCaption">Grow your network across 40 guaranteed meetings with MICE professionals.    </p>
                </div>
                </div>

              
                <div
                className="col-lg-4 ml-rg-auto py-5"
              >
                  <div className="programmeItem">
                   <img
                alt={" "}
                src={Experience}
                className={"sampleThumbnails"}
              ></img>
              <h1 className="itemHeading">Experience</h1>
               <p className="itemCaption">Embark on destination discovery activities that bring you more opportunities to connect authentically and build long lasting business relationships. </p>
                </div>
                </div>

               
                <div
                className="col-lg-4 ml-rg-auto py-5"
              >
                 <div className="programmeItem">
                   <img
                alt={" "}
                src={Enjoy}
                className={"sampleThumbnails"}
              ></img>
              <h1 className="itemHeading">Connect</h1>
               <p className="itemCaption">Strengthen your business relationships during evening events hosted at top MICE venues.  </p>
                </div>
                </div>

              </div>
            </div>
          </div>
          <div className="packages-section">
            <div className="container">
              <div className="row no-gutters align-items-stretch">
                <div className="col-lg-12 ml-lg-auto py-5 ">

                  <h2 className="site-section-heading font-secondary">
                    <span style={{fontSize:"20px"}}>
                      What's in it for you?
                    </span>
                  </h2>

                  <div className="tabs">
        <div
          className={`tab ${activeTab === 'suppliers' ? 'active' : ''}`}
          onClick={() => toggleAccordion('suppliers')}
        >
          Suppliers
        </div>
        <div className="separator">|</div>
        <div
          className={`tab ${activeTab === 'buyers' ? 'active' : ''}`}
          onClick={() => toggleAccordion('buyers')}
        >
          Buyers
        </div>
      </div>
      <div className={`accordion ${activeTab === 'suppliers' ? 'open' : ''}`}>
        {/* Suppliers content */}
        <div className="content">

        <ul className='packages'>
                                                        <li>
                                                        Grow your buyer network across 3 days of guaranteed 1-2-1 meetings and networking opportunities 
                                                        </li>
                                                        <li>
                                                        Benefit from a meetings schedule that’s built around your preferences, bringing you relevant business connections and lasting ROI  
                                                        </li>
                                                        <li>
                                                        Maximise your facetime with buyers through engaging destination activities 
                                                        </li>
                                                        <li>
                                                        Build on your connections during networking dinners and evening events 
                                                        </li>
                                                        <li>
                                                        Be guided from start to finish by a dedicated Account Manager 
                                                        </li>
                                                       
                                                     
                                                    </ul>

          
        </div>
      </div>
      <div className={`accordion ${activeTab === 'buyers' ? 'open' : ''}`}>
        {/* Buyers content */}
        <div className="content">

       
        <ul className='packages'>
                                                        <li>
                                                        Grow your network of high-quality international suppliers through guaranteed 1-2-1 meetings 
                                                        </li>
                                                        <li>Benefit from a meetings schedule that’s built around your preferences, bringing you relevant business connections and guaranteed return on time 
                                                        </li>

                                                        <li>
                                                        Get a bespoke buyer package that includes accommodation, transfers, F&B, and airfare credit 
                                                        </li>
                                                        <li>
                                                        Experience the MICE highlights of Dubai through site visits and destination discovery 
                                                        </li>
                                                    </ul>
        </div>
      </div>

      
              
                 
                </div>
              
              </div>
            </div>
          </div>

          <div
            className="cta-banner"
            style={{ backgroundImage: `url(${CTA})` }}
          >
            <div className="container">
              <div className="row no-gutters align-items-stretch">
                <div className="col-lg-12 ml-rg-auto py-5 laptop">
                  <div className="cta-content">
                  <div
                    className="footer-section-heading "
                 
                  >
                  <h3>m&i Flagship Dubai</h3>
                   <h1>Experience an event with real ROI  </h1>
                  </div>
                    <a href="/signup">
                      <span className=" btn btn-primary">
                      Register your interest
                      </span>
                    </a>
                    <br/><br/><br/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        


        
        </div>
      </div>
    </>
  );
};

export default Dubai;
