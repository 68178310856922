import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import Carousel from "../../components/elements/Carousel"
import Sticky from "react-sticky-el";
import "../../assets/scss/style.scss";
import EpicSana from "../../assets/images/events/partner_logos/EpicSana.jpg"
import Connect from "../../assets/images/events/2025/zagreb/meet.png";
import Experience from "../../assets/images/events/2025/zagreb/experience.png";
import Enjoy from "../../assets/images/events/2025/zagreb/connect.png";
import CTA from "../../assets/images/events/2025/zagreb/cta.png";
import Spotlight from "../../assets/images/events/2025/zagreb/spotlight.png";
import Accommodation from "../../assets/images/events/2025/zagreb/accommodation.png";
import PortugalTravel from "../../assets/images/events/partner_logos/Portugal_Travel.jpg";
import Vega from "../../assets/images/events/partner_logos/vega_logo.png";
import Fairmont from "../../assets/images/events/2025/zagreb/scandic.png";
import FairmontB from  "../../assets/images/events/2025/lakelouise/fairmont-b.png";
import BLL from  "../../assets/images/events/2025/lakelouise/BLL.png";
import Header from "../../assets/images/events/2025/zagreb/header.png";
import RareIndigo from "../../assets/images/events/partner_logos/rareindigo.jpg"
import PartnerBlack from "../../assets/images/events/2025/zagreb/partner.png";
import PartnerWhite from "../../assets/images/events/2025/zagreb/partner-white.png";
import Maistra from  "../../assets/images/events/2025/zagreb/maistra.jpg";

const Zagreb = () => {

  const slides = [
    {
      backgroundImage: `url(${Accommodation})`,
      title: 'Accommodation and meeting venue',
      imageSrc: PartnerWhite,
      content: 'In the heart of Croatia’s capital sits Zonar Zagreb, a newly refurbished 299-room property for travellers and businesspeople alike. Guests can look forward to chic interiors, nine flexible conference rooms with state-of-the-art facilities, and a rooftop pool perfect for relaxing after a busy day of meetings. ',
      website: 'https://www.maistra.com/',
      width: '200px', 
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  
  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % slides.length);
  };
  
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((currentSlide + 1) % slides.length);
    }, 5000);
  
    return () => clearInterval(intervalId);
  }, [currentSlide, slides.length]);
  
  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + slides.length) % slides.length);
  };
  
  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  
    const [activeTab, setActiveTab] = useState('suppliers'); // Set the initial active tab to 'suppliers'

    const toggleAccordion = (tabName) => {
      setActiveTab(activeTab === tabName ? null : tabName);
    };

    const videoEl = useRef(null);

const attemptPlay = () => {
  videoEl &&
    videoEl.current &&
    videoEl.current.play().catch(error => {
      console.error("Error attempting to play", error);
    });
};
const handleScroll = () => {
      const sectionElement = document.getElementById('scrollto');
      // If you want to scroll to a specific section by its ID

    // const percentageToScroll = 0.5;
    // const offset = window.innerHeight * percentageToScroll;
    // If you want to scroll a certain percentage down the page

    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
   
  const textRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    
      

  useEffect(() => {

    
attemptPlay();

   

   
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);

     
    };

    
  }, []);

  const images = [
    
    [
      PartnerBlack, Maistra
    ]
  ];
 

  
  const links = [
   
    ['https://www.maistra.com/#/ ', 'https://www.maistra.com/#/']
  ];
  const headings = ['Headline Partner'];


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>m&i Express Zagreb | UK & EU buyers  </title>
        <meta
          name="description"
          content="The ultimate B2B experience for the luxury incentive travel industry  "
        />
        <meta
          property="og:url"
          content={
            "https://www.mi-forums.com/images/ographs/OG_image_Europe_Autumn_2022.png"
          }
        />
        <meta
          property="og:image"
          content={
            "https://www.mi-forums.com/images/ographs/OG_image_Europe_Autumn_2022.png"
          }
        />
      </Helmet>
      <div className="site-wrap">
        <div className="event-page twentyfour lakelouise powder-bg">
          <div
            className="sprint-hero-slider" style={{
              backgroundImage: `url(${Header})`,
          }}>

            <div class="video-container">
            <iframe className="hero-background-video" width="100%" height="100%" src=" https://www.youtube.com/embed/k8Qn1gFB9yg?si=i7BGBTEzN3WP-qFQ?rel=0&autoplay=1&mute=1&playsinline=1&controls=0&&showinfo=0&loop=1&playlist=k8Qn1gFB9yg" frameborder="0" allowfullscreen="allowfullscreen"></iframe>

           
        
        </div>

<div className="row no-gutters align-items-stretch">
                  <div className="header-section-heading">
                  {/* <h3>Express</h3> */}
                

                   <h1>m&i Express Zagreb</h1>
                   <h3>UK & EU buyers </h3>
                   <h4>27-29 Aug | Croatia </h4>
                   <div className="scroll-arrow" onClick={handleScroll} >
     <svg width="70" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" fill="#E0C7B2">
<g><path d="M500,10"/><path d="M500,990L108,598h784L500,990z"/></g>
</svg>
    </div>
                 
                  </div>
              </div>


         
          </div>

          <Sticky>
            <div className="event-under-banner twentyfour raven-bg" id="scrollto">
              <div className="container">
                <div className="row no-gutters align-items-stretch">
                  <div className=" ml-rg-auto event-sticky-text">
                    <h3>Love the sound of this event?</h3>
                  </div>
                  <div className="ml-rg-auto event-buttons flex-wrap ">
                    <a href="/tell-me-more">
                      <span className=" btn btn-secondary transparent">
                        Tell me more
                      </span>
                    </a>
                    <a href="/signup">
                      <span className=" btn btn-secondary grey">
                      Register now
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Sticky>
       
          <div className="site-half eventInformationSection powder-bg" >
            <div className="container">
              <div className="row no-gutters center-content">
             
              <div className="col-lg-10 mr-lg-auto py-5" style={{paddingRight:'20px'}}>
                <h2>Benefit from our signature m&i ROI over just two<br/> impactful days. </h2>
                <br/>
                <p>
                A high-value itinerary over a more concentrated timeframe, m&i Express is streamlined to maximise your return on investment whilst valuing your time. This Forum promises to deliver our signature ROI guarantee in just two days. Take advantage of our classic programme of 1-2-1 meetings, destination activities, and unforgettable evenings: a schedule designed to maximise opportunities and build lasting business connections.
                </p>
              </div>

              </div>
            </div>
            <div className="anchorScroll" id="numbers"></div>
          </div>

          <div className="numbers-section eventNumbers powder-bg">
            <div className="container">
              <div className="row no-gutters align-items-stretch">
                <div
                  className="col-lg-12 ml-rg-auto py-4 laptop"
                  style={{ color: "#fff" }}
                >
                  <div className="container">
                    <div className="inNumbers">
                    
                      <ul>
                        <li>
                          <div className="numbers-caption ">
                            <h1 className="eventNumbers raven">2</h1>
                            <p>Days</p>
                          </div>
                        </li>
                        <li>
                          <div className="numbers-caption">
                            <h1 className="eventNumbers raven">25</h1>
                            <p>1-2-1 Meetings</p>
                          </div>
                        </li>
                        <li>
                          {" "}
                          <div className="numbers-caption">
                            <h1 className="eventNumbers raven">120</h1>
                            <p>Supplier tables</p>
                          </div>
                        </li>
                        <li>
                          <div className="numbers-caption">
                            <h1 className="eventNumbers raven">130</h1>
                            <p>UK & European buyers</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="anchorScroll" id="spotlight"></div>
            </div>
          </div>


         
          <div className="row splitrow explore" style={{backgroundColor:"#E8F0FA"}}>
                        <div class="column leftSide afternoons"  >
                        <div className='col-lg-12 ml-rg-auto py-5'>
                              
                        <div className="container">
            <div className="row no-gutters align-items-stretch">
              <div
                className="col-lg-7 ml-rg-auto "
              >
               
                <h1 className="site-section-heading font-secondary">
                Spotlight on Zagreb
                </h1>
               
            
                <p
                    
                    className="mobilePadding-2"
                  >
    An up-and-coming MICE hotspot, Zagreb’s convenient location just 27km from the nearest airport and impressive inner-city transport links make travel easy, while its ever-increasing selection of 5-star hotels make it an obvious choice for travel planners.  
                    <br />
                    <br />
                    An old city with a young soul, Zagreb combines modern infrastructure with ancient history, a story told through its museums, cathedrals, and quaint alleyways. If you’re looking for a vibrant MICE-ready city with a story to tell, Zagreb is the destination for you. 
                  </p>
                 
                  </div>
            </div>
          </div>
                            
                            
                            </div>

            </div>

                <div className="column rightSide afternoons" style={{ backgroundImage: `url(${Spotlight})` }}>
                            <div className='col-lg-12 ml-rg-auto py-5'>
                          
                          
                            </div>
                </div>
        </div>

       

      <div className="row splitrow celebrate" style={{backgroundColor:"#352E35"}}>
                      <div class="column leftSide celebrate" style={{ backgroundImage: slides[currentSlide].backgroundImage }}>
                      <div className='col-lg-12 ml-rg-auto py-5'>
                            
                          
                          
                          </div>

          </div>

          <div className="column rightSide celebrate">
        <div className='col-lg-12 ml-rg-auto py-5'>
          <div className="container carousel-content-container">
            <div className='col-lg-12 ml-lg-auto py-5' style={{ textAlign: 'center' }}>
              <h3 className='site-section-heading font-secondary' style={{ textDecoration: 'underline' }}>
                {slides[currentSlide].title}
              </h3>
              <div className="carousel-accommodation">
                <div className="carousel-accommodation-logo">
                  <a href={slides[currentSlide].website} target="_blank">
                    <img alt=" " src={slides[currentSlide].imageSrc} className="carousel-accommodationlogo" style={{ width: slides[currentSlide].width }}></img>
                  </a>
                </div>
                <br />
                <p>{slides[currentSlide].content}</p>
                <a href={slides[currentSlide].website} target="_blank">
                  <span className="btn btn-secondary transparent">Visit website</span>
                </a>
             
              </div>
                 
            </div>
          </div>
        </div>
      </div>

     
     
              {/* <button onClick={prevSlide}>Previous</button>
      <button onClick={nextSlide}>Next</button> */}
      </div>


        <div className="site-half partnerSection">
            <div className="container">
              <div className="row no-gutters align-items-stretch">
                <div className="col-lg-12 ml-rg-auto py-5">
                  <h3 className="site-section-heading font-secondary">
                    
                  </h3>

                  <Carousel images={images} links={links} headings={headings} autoPlay />
                  
                </div>
              </div>
            </div>
          </div>

      

        {/* <div className="site-half block" style={{ paddingBottom: "5rem" }}>
        <div id="scroll-container">
  <div id="scroll-text">Meet with a wider range of MICE professionals at our Flagship Forum in Lisbon. </div>
</div>
    </div> */}

      

    {/* <div className='event-venue'>
                        <div className='container'>
                            <div className='row no-gutters align-items-stretch'>
                              

                            </div>
                            <div className='anchorScroll' id='itinerary'></div>
                        </div>
                    </div>  */}

          <div className="site-half sampleProgramme raven-bg">
          <h1>What to expect</h1>
            <div className="container">
              <div className="row no-gutters align-items-stretch">
               
             
              <div
                className="col-lg-4 ml-rg-auto py-5"
              >
                 <div className="programmeItem">
                   <img
                alt={" "}
                src={Connect}
                className={"sampleThumbnails"}
              ></img>
               <h1 className="itemHeading">Meet</h1>
               <p className="itemCaption">Grow your network across 25 guaranteed high-quality meetings with MICE professionals.  </p>
                </div>
                </div>

              
                <div
                className="col-lg-4 ml-rg-auto py-5"
              >
                  <div className="programmeItem">
                   <img
                alt={" "}
                src={Experience}
                className={"sampleThumbnails"}
              ></img>
              <h1 className="itemHeading">Experience</h1>
               <p className="itemCaption">Embark on destination discovery activities that bring you more opportunities to connect authentically and build long lasting business relationships. </p>
                </div>
                </div>

               
                <div
                className="col-lg-4 ml-rg-auto py-5"
              >
                 <div className="programmeItem">
                   <img
                alt={" "}
                src={Enjoy}
                className={"sampleThumbnails"}
              ></img>
              <h1 className="itemHeading">Connect</h1>
               <p className="itemCaption">Strengthen your business relationships during evening events hosted at top MICE venues.</p>
                </div>
                </div>

              </div>
            </div>
          </div>
          <div className="packages-section">
            <div className="container">
              <div className="row no-gutters align-items-stretch">
                <div className="col-lg-12 ml-lg-auto py-5 ">

                  <h2 className="site-section-heading font-secondary">
                    <span style={{fontSize:"20px"}}>
                      What's in it for you?
                    </span>
                  </h2>

                  <div className="tabs">
        <div
          className={`tab ${activeTab === 'suppliers' ? 'active' : ''}`}
          onClick={() => toggleAccordion('suppliers')}
        >
          Suppliers
        </div>
        <div className="separator">|</div>
        <div
          className={`tab ${activeTab === 'buyers' ? 'active' : ''}`}
          onClick={() => toggleAccordion('buyers')}
        >
          Buyers
        </div>
      </div>
      <div className={`accordion ${activeTab === 'suppliers' ? 'open' : ''}`}>
        {/* Suppliers content */}
        <div className="content">

        <ul className='packages'>
                                                        <li>
                                                        Grow your buyer network across two days of guaranteed 1-2-1 meetings and networking opportunities  
                                                        </li>
                                                        <li>
                                                        Save time through our effective and compact schedule 
                                                        </li>
                                                        <li>
                                                        Benefit from a meetings schedule that’s built around your preferences, bringing you relevant business connections and lasting ROI    
                                                        </li>
                                                        <li>
                                                        Maximise your facetime with buyers through engaging destination activities 
                                                        </li>
                                                        <li>
                                                        Build on your connections during networking dinners and evening events  
                                                        </li>
                                                       
                                                        <li>Be guided from start to finish by a dedicated Account Manager  </li>
                                                      
                                                     
                                                    </ul>

          
        </div>
      </div>
      <div className={`accordion ${activeTab === 'buyers' ? 'open' : ''}`}>
        {/* Buyers content */}
        <div className="content">

       
        <ul className='packages'>
                                                        <li>
                                                        Grow your network of high-quality European suppliers through guaranteed 1-2-1 meetings
                                                        </li>
                                                        <li>Save time through our effective and compact schedule  </li>
                                                        <li>Benefit from a meetings schedule that’s built around your preferences, bringing you relevant business connections and guaranteed return on time   </li>
                                                        <li>
                                                        Get a bespoke buyer package that includes accommodation, transfers, F&B, and airfare credit  
                                                        </li>

                                                        <li>
                                                        Experience the MICE highlights of Zagreb through site visits and destination discovery   
                                                        </li>
                                                        
                                                    </ul>
        </div>
      </div>

      
              
                 
                </div>
              
              </div>
            </div>
          </div>

          <div
            className="cta-banner"
            style={{ backgroundImage: `url(${CTA})` }}
          >
            <div className="container">
              <div className="row no-gutters align-items-stretch">
                <div className="col-lg-12 ml-rg-auto py-5 laptop">
                  <div className="cta-content">
                  <div
                    className="footer-section-heading "
                 
                  >
                  <h3>m&i Express Zagreb</h3>
                   <h1>Get the m&i Forum <br/>experience in just two days  </h1>
                   {/* <h3 className="caption">Save your space today. </h3> */}
                  </div>
                    <a href="/signup">
                      <span className=" btn btn-primary">
                      Register your interest
                      </span>
                    </a>
                    <br/><br/><br/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        


        
        </div>
      </div>
    </>
  );
};

export default Zagreb;
