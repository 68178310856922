import React from "react"
import { Helmet } from "react-helmet"
import "../assets/scss/style.scss"

const TravelAccommodation = () => {
  return (
    <>
      <div class="form-container">
       <div id="ftEmbedForm-ft1641575134581-566-73"></div>
      </div>


      <Helmet>
    <script src="https://efress.formtitan.com/publish/v1/embed?form=travel-accommodation&width=1000&height=3500&guid=ft1641575134581-566-73" type="text/javascript"/>
</Helmet>
    </>
  )
}

export default TravelAccommodation
