import React from "react"
import "../assets/scss/style.scss"

import { Helmet } from "react-helmet"

const BuyerNetworkingProfile = () => {
  
  return (
    <>
    
    <div class="form-container">
    <div id="ftEmbedForm-ft1712764519595-949-93"></div>
      </div>


      <Helmet>
    <script src="https://efress.formtitan.com/publish/v1/embed?form=ft77df5f2d1712758648501&width=1000&height=3190&guid=ft1712764519595-949-93" type="text/javascript"/>

</Helmet>
    </>
  )
}

export default BuyerNetworkingProfile
